<template>
    <div>
        <!--        TODO: remove https://aliengain.atlassian.net/browse/BP-30936-->
        <div v-if="isVisible && useOldCampaignCoupon" class="campaign-message-old">
            <div class="campaign-message-icon">
                <SvgIcon class="icon-size-large" icon-id="icon-gift" vertical-align="text-top" />
            </div>
            <div class="campaign-message-content">
                <p class="text bold">
                    {{
                        $t('ui.campaignMessage.refundStakeBack', {
                            amount: $options.filters.currency(Number(campaign.campaignAmount), currency.format, 0),
                        })
                    }}
                </p>
                <p class="text-small">
                    {{ $t('ui.campaignMessage.validUntil', { date: expiredDate }) }}
                    <router-link class="underline" :to="{ path: externalPath.RFB_RULES }" @click.native="clickReadMore">
                        {{ $t('ui.betslip.readMore') }}.
                    </router-link>
                </p>
            </div>
        </div>
        <div v-else-if="isVisible" class="campaign">
            <div class="campaign-message">
                <div class="campaign-message-icon">
                    <SvgIcon class="icon-size-large" icon-id="icon-gift" vertical-align="text-top" />
                </div>
                <div class="campaign-message-content">
                    <div class="text bold">
                        {{
                            $t('ui.campaignMessage.refundStakeBack', {
                                amount: $options.filters.currency(Number(campaign.campaignAmount), currency.format, 0),
                            })
                        }}
                        <router-link class="underline link" :to="{ path: externalPath.RFB_RULES }" @click.native="clickReadMore">
                            {{ $t('ui.common.learnMore') }}
                        </router-link>
                    </div>
                    <div class="text-small">
                        {{ $t('ui.campaignMessage.validUntil', { date: expiredDate }) }}
                    </div>
                </div>
            </div>
            <div class="campaign-coupon">
                <button @click="useRiskFree()">
                    <SvgIcon v-if="isUsingCampaignEligibility" class="check-icon" icon-id="icon-checkbox" />
                    <span v-else>{{ $t('ui.campaignMessage.use') }}</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { action, getter as platformGetter } from '@/modules/platform/store/const';
import helper from '@/modules/core/utils/helper';
import { externalPath } from '@/router/external-path';

export default {
    name: 'CampaignMessage',
    props: {
        campaign: {
            type: Object,
            required: true,
        },
    },
    computed: {
        externalPath() {
            return externalPath;
        },
        ...mapState({
            currency: (state) => state.platform.settings.currency,
        }),
        ...mapGetters({
            dateOptions: platformGetter.GET_DATE_OPTIONS,
            isUsingCampaignEligibility: platformGetter.GET_IS_USING_CAMPAIGN_ELIGIBILITY,
            brandPreferences: platformGetter.GET_BRAND_PREFERENCE,
        }),
        expiredDate() {
            return (
                helper.formatDateTime(new Date(this.campaign.eligibleUntil), {
                    ...this.dateOptions,
                    needTime: false,
                    isUtc: true,
                }) + `/${new Date(this.campaign.eligibleUntil).getFullYear()}`
            );
        },
        // TODO: remove. https://aliengain.atlassian.net/browse/BP-30936
        useOldCampaignCoupon() {
            return !this.brandPreferences.useNewCampaignCoupons;
        },
        isVisible() {
            return this.campaign && this.campaign?.campaignAmount && this.campaign?.eligibleUntil;
        },
    },
    methods: {
        ...mapActions({
            setUsingCampaignEligibility: action.SET_USING_CAMPAIGN_ELIGIBILITY,
        }),
        clickReadMore() {
            this.$gtm.query({ event: 'risk_free_bet_read_more_click' });
        },
        useRiskFree() {
            this.setUsingCampaignEligibility(!this.isUsingCampaignEligibility);
        },
    },
};
</script>

<style scoped lang="scss">
//TODO: remove it https://aliengain.atlassian.net/browse/BP-30936
.campaign-message-old {
    padding: 12px;
    display: flex;
    align-items: flex-start;
    background-color: $message-success;
    margin-top: 10px;
    margin-left: -8px;
    margin-right: -8px;

    &-content {
        color: $main-text;

        .amount {
            color: $main-text;
            display: inline;
        }
    }

    &-icon {
        margin-right: 8px;
    }

    .text-small {
        a:hover {
            color: inherit;
            opacity: 0.7;
        }
    }
}

$campaign-border-color: #c7d9a3;
.campaign {
    @extend %body-normal-font-400;
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 12px;

    @include minbasic {
        flex-direction: row;
    }
    @include tablet {
        flex-direction: column;
    }
    @include mq-md {
        flex-direction: row;
    }

    &-message {
        @mixin vertical {
            align-items: baseline;
            border-bottom: none;
            border-left: solid 1px $campaign-border-color;
            border-top: solid 1px $campaign-border-color;
            border-right: solid 1px $campaign-border-color;
            border-radius: 4px 4px 0 0;
        }
        @mixin horizontal {
            align-items: center;
            border-bottom: solid 1px $campaign-border-color;
            border-left: solid 1px $campaign-border-color;
            border-top: solid 1px $campaign-border-color;
            border-right: none;
            border-radius: 4px 0 0 4px;
        }

        position: relative;
        display: flex;
        align-items: baseline;
        width: 100%;
        padding: 12px;
        background-color: $message-success;
        @include vertical;

        &-icon {
            margin-right: 8px;
        }

        @include minbasic {
            @include horizontal;
        }
        @include tablet {
            @include vertical;
        }
        @include mq-md {
            @include horizontal;
        }

        &:before {
            @mixin vertical {
                width: 8px;
                height: 16px;
                bottom: -8.5px;
                left: -1px;
                right: auto;
                top: auto;
                border-radius: 0 8px 8px 0;
                border-top: 1px solid $campaign-border-color;
                border-left: none;
            }
            @mixin horizontal {
                width: 16px;
                height: 8px;
                top: -1px;
                right: -8.5px;
                left: auto;
                bottom: auto;
                border-radius: 0 0 8px 8px;
                border-top: none;
                border-left: 1px solid $campaign-border-color;
            }
            content: '';
            position: absolute;
            border-right: 1px solid $campaign-border-color;
            border-bottom: 1px solid $campaign-border-color;
            background-color: $light-grey;
            @include vertical;

            @include minbasic {
                @include horizontal;
            }
            @include tablet {
                @include vertical;
            }
            @include mq-md {
                @include horizontal;
            }
        }
        &:after {
            @mixin vertical {
                width: 8px;
                height: 16px;
                bottom: -8.5px;
                right: -1px;
                left: auto;
                top: auto;
                border-radius: 8px 0 0 8px;
                border-bottom: 1px solid $campaign-border-color;
                border-right: none;
            }
            @mixin horizontal {
                width: 16px;
                height: 8px;
                bottom: -1px;
                right: -8.5px;
                left: auto;
                top: auto;
                border-radius: 8px 8px 0 0;
                border-right: 1px solid $campaign-border-color;
                border-bottom: none;
            }

            content: '';
            position: absolute;
            border-top: 1px solid $campaign-border-color;
            border-left: 1px solid $campaign-border-color;
            background-color: $light-grey;
            @include vertical;

            @include minbasic {
                @include horizontal;
            }
            @include tablet {
                @include vertical;
            }
            @include mq-md {
                @include horizontal;
            }
        }
        &-content {
            .bold {
                .link {
                    font-weight: normal;
                    &:hover {
                        color: inherit;
                        opacity: 0.7;
                    }
                }
            }
        }
    }
    &-coupon {
        @mixin vertical {
            border-radius: 0 0 4px 4px;
            border-top: dashed 1px $campaign-border-color;
            border-left: solid 1px $campaign-border-color;
        }
        @mixin horizontal {
            border-radius: 0 4px 4px 0;
            border-top: solid 1px $campaign-border-color;
            border-left: dashed 1px $campaign-border-color;
        }

        display: flex;
        align-items: center;
        padding: 12px;
        background-color: $message-success;
        border-bottom: solid 1px $campaign-border-color;
        border-right: solid 1px $campaign-border-color;
        @include vertical;

        button {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0;
            border: 0;
            padding: 7px 12px;
            color: $light-grey;
            background-color: $dark-green;
            text-transform: uppercase;
            cursor: pointer;
            width: 100%;
            height: 32px;
            font-weight: 600;

            @include minbasic {
                width: 80px;
            }
            @include mintablet {
                width: 146px;
            }
            @include tablet {
                width: 100%;
            }
            @include mq-md {
                width: 80px;
            }

            .check-icon {
                width: 14px;
                height: 14px;
                fill: $green;
            }
        }

        @include minbasic {
            @include horizontal;
        }
        @include tablet {
            @include vertical;
        }
        @include mq-md {
            @include horizontal;
        }
    }
}
</style>
