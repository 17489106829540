<template>
    <div class="event-pool">
        <Modal v-if="!isSingleGameJackpot" name="how-to-play" :width="'100%'" :spinner-triggers="spinnerTriggers" @close="closeInfo()">
            <div v-if="rules && rules.content">
                <DynamicComponent v-for="(item, index) in rules.content" :key="'rule' + index" :data="item" :path="'content'" />
            </div>
            <div v-else-if="contentError">
                <renderer :input="contentError" />
            </div>
        </Modal>
        <Jackpot :jackpot="pool" show-description>
            <template slot="lucky">
                <div class="table lucky-dip-slot">
                    <div class="row-cell">
                        <a class="row-cell button button-lucky-dip" @click.stop.prevent="getRandomSelection">
                            {{ $t('ui.jackpot.luckyDip') }}
                        </a>
                        <div v-if="!isSingleGameJackpot" class="row-cell align-middle jackpot-info" @click="openInfo()">
                            <SvgIcon class="icon-info" icon-id="icon-info" />
                        </div>
                    </div>
                </div>
            </template>
        </Jackpot>
        <div class="events">
            <div class="events-header">
                <div class="title">{{ $t('ui.jackpot.events') }}</div>
                <div>{{ $t('ui.jackpot.selectOneOrMore') }}</div>
            </div>
            <div>
                <div
                    v-for="(row, eventIndex) of pool.events"
                    :key="row.id"
                    class="events-item"
                    :data-test-id="!isSingleGameJackpot && `event-${row.event.id}`"
                >
                    <div class="events-item-match">
                        <h3>{{ row.name }}</h3>
                        <h4 v-if="!isSingleGameJackpot && row.event.startDate">
                            {{ formatDateTime(row.event.startDate, { ...dateOptions, isUtc: true }) }} - {{ row.event.competition }}
                        </h4>
                    </div>
                    <div class="events-item-selection">
                        <div class="events-item-selection-wrapper">
                            <div
                                v-for="(variation, index) of variations"
                                :key="row.id + variation"
                                class="cell"
                                :data-test-id="`selection-${index}`"
                            >
                                <div
                                    class="button full-width"
                                    :class="[
                                        isActive(index + 1, eventIndex) ? 'pool-button-accent' : 'button-accent-default',
                                        { table: $mq.isXXSmall },
                                    ]"
                                    @click="selectOption(variation, index + 1, eventIndex, row)"
                                >
                                    <div class="full-height" :class="$mq.isXXSmall ? 'row-cell align-middle' : 'table'">
                                        <div :class="{ 'row-cell align-middle': !$mq.isXXSmall }">
                                            {{ (row.outcomes && row.outcomes[index].text) || variation }}
                                        </div>
                                        <div v-if="row.outcomes" class="odd bold" :class="{ 'row-cell align-middle': !$mq.isXXSmall }">
                                            {{ getEventPrice(row.outcomes, index) }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="poolTiebreakers.length" class="events-item">
                    <Modal name="tiebreakers-info" width="100%" @close="$modal.hide('tiebreakers-info')">
                        {{ $t('ui.jackpot.tiebreakers.info') }}
                    </Modal>
                    <div class="tiebreaker-title">
                        <span class="bold">{{ $t('ui.jackpot.tiebreakers.title') }}</span>
                        <span class="jackpot-info" @click="$modal.show('tiebreakers-info')">
                            <SvgIcon class="icon-info" icon-id="icon-info" />
                        </span>
                    </div>
                    <div
                        v-for="(tie, index) in $v.tiebreakers.$each.$iter"
                        :key="`${index}-tiebreaker-${tie.$model.id}`"
                        class="table tiebreaker"
                    >
                        <div class="row-cell align-middle bold tiebreaker-question">{{ tie.$model.question }}</div>
                        <InputField
                            class="row-cell align-middle"
                            :name="`${pool.id}-tiebreaker-${index}`"
                            type="number"
                            stringify
                            :value="getTiebreaker(tie.$model.id)"
                            :v="tie.value"
                            :attrs="{ maxLength: 3, inputmode: 'numeric' }"
                            :form-name="`tiebreaker-${index}`"
                            :handlers="{ 'submit.prevent': true }"
                            :error-messages="{
                                numeric: $t('ui.common.form.error.onlyDigits'),
                                required: $t('ui.common.form.error.required'),
                                between: $t('ui.jackpot.tiebreakers.errorBetween'),
                            }"
                            @value="updateTiebreaker({ id: tie.$model.id, value: Number($event) || $event })"
                        />
                    </div>
                </div>
            </div>
            <div id="jp-main-bottom" class="events-summary">
                <div class="row">
                    <div>{{ $t('ui.jackpot.price') }}</div>
                    <div>
                        <b>{{ pool.fee | currency(currencyFormat, decimalBetPrecision) }}</b>
                    </div>
                </div>
                <div class="row">
                    <div>{{ $t('ui.jackpot.totalTickets') }}:</div>
                    <div>
                        <b>{{ getTotalTickets }}</b>
                    </div>
                </div>
                <div v-if="pool.freeTicketCount" class="row">
                    <div>{{ $t('ui.jackpot.freeTickets') }}:</div>
                    <div>
                        <b>{{ pool.freeTicketCount }}</b>
                    </div>
                </div>
                <div class="row">
                    <div>{{ $t('ui.jackpot.totalPrice') }}:</div>
                    <div>
                        <b>{{ getTotalPrice | currency(currencyFormat, decimalBetPrecision) }}</b>
                    </div>
                </div>
                <div v-if="error" class="notify error">
                    <renderer :input="error" />
                </div>
                <input
                    id="buy"
                    type="button"
                    class="button button-primary button-full"
                    :disabled="!isValid"
                    :value="$t('ui.jackpot.buyTicket')"
                    @click.prevent="buyTicket"
                />
            </div>
            <div class="events-clear-selection">
                <a class="underline" @click="getCleanSelection">{{ $t('ui.jackpot.clearAll') }}</a>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { required, numeric, between } from 'vuelidate/lib/validators';

import { helper, android } from '@/modules/core';
import { InputField } from '@/modules/core/components';
import { jackpotType } from '@/modules/sport';
import { action as sportAction } from '@/modules/sport/store/const';
import { action as generalAction, getter as generalGetter } from '@/modules/platform/store/const';
import { getter as authGetter } from '@/modules/platform/store/modules/auth/const';
import { DynamicComponent } from '@/modules/platform/components';

import Modal from '@/js/plugins/modal/Modal.vue';

import Jackpot from './Jackpot';

export default {
    name: 'EventPool',
    components: { InputField, Jackpot, Modal, DynamicComponent },
    props: {
        pool: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            valid: false,
            variations: ['1', 'X', '2'],
            tiebreakers: [],
            ticket: null,
            showModal: false,
            modalContentKey: 'JACKPOT_LUCKY_DIP_RULES',
            spinnerTriggers: [generalAction.GET_CONTENT],
        };
    },
    validations() {
        return {
            tiebreakers: {
                $each: {
                    value: {
                        required,
                        numeric,
                        between: between(0, 120),
                    },
                },
            },
        };
    },
    computed: {
        ...mapState({
            decimalBetPrecision: (state) => state.platform.settings.betting.real.precision,
            error: (state) => state.sport.jackpot.error,
            contentError: (state) => state.platform.content.error.message,
            selected: (state) => state.sport.jackpot.selected,
            currencyFormat: (state) => state.platform.settings.currency.format,
        }),
        ...mapGetters({
            dateOptions: generalGetter.GET_DATE_OPTIONS, // Implement global date time BP-17850
            isAuthenticated: authGetter.IS_AUTHENTICATED,
        }),
        getTotalPrice() {
            return this.pool.fee * Math.max(this.getTotalTickets - (this.pool.freeTicketCount || 0), 0);
        },
        getTotalTickets() {
            if (!this.valid) {
                return 0;
            }
            return this.selected.reduce((prev, next) => {
                const amount = next.length;
                if (amount === 2) {
                    prev = 2 * prev;
                } else if (amount === 3) {
                    prev = 3 * prev;
                }
                return prev;
            }, 1);
        },
        rules() {
            return this.$store.getters[generalGetter.GET_CONTENT_SLOTS][this.modalContentKey];
        },
        isSingleGameJackpot() {
            return [jackpotType.SINGLE, jackpotType.MULTIJURISDICTION].includes(this.pool.type);
        },
        poolTiebreakers() {
            return this.pool.tiebreakers?.tiebreakers || [];
        },
        isTiebreakersValid() {
            return !this.poolTiebreakers || !this.$v.$invalid;
        },
        isValid() {
            return this.valid && this.isTiebreakersValid;
        },
    },
    watch: {
        ticket(ticket) {
            this.$emit('ticket', ticket);
        },
        'pool.tiebreakers.tiebreakers': {
            immediate: true,
            handler(tiebreakers) {
                tiebreakers?.length && this.updateTiebreaker();
            },
        },
        selected() {
            this.$store.dispatch(sportAction.GENERATE_JACKPOT_UUID);
        },
    },
    created() {
        this.createSelected();
        this.validate();
    },
    methods: {
        replaceAt: helper.replaceAt,
        formatDateTime: helper.formatDateTime,
        getEventPrice(outcomes, index) {
            const price = outcomes[index]?.odds;
            return Number(price) ? price.toFixed(2) : null;
        },
        closeInfo() {
            this.$modal.hide('how-to-play');
        },
        openInfo() {
            this.$store.dispatch(generalAction.GET_CONTENT, this.modalContentKey);
            this.$gtm.query({
                event: 'info_icon_click',
                product: 'jackpot',
                isAuthenticated: this.isAuthenticated,
            });
            this.$modal.show('how-to-play');
        },
        buyTicket() {
            if (!this.isAuthenticated) {
                this.$store.dispatch(sportAction.SET_JACKPOT_ERROR, this.$t('ui.common.error.loginOrSignUp'));
                this.$gtm.query({
                    event: 'jackpot_failed',
                    reason: 'not logged in',
                });
                return;
            }
            const poolId = this.pool.id;
            const poolName = this.pool.name;
            const selections = this.pool.rows.map((row, index) => ({
                position: row.position,
                outcomes: this.selected[index],
            }));
            const rows = this.getTotalTickets;
            const price = this.getTotalPrice;
            const tiebreakers = this.tiebreakers.map(({ id, value }) => ({ id, value }));

            this.$store
                .dispatch(sportAction.ADD_JACKPOT_TICKET, {
                    poolId,
                    poolName,
                    rows,
                    selections,
                    price,
                    tiebreakers,
                })
                .then((ticket) => {
                    if (ticket) {
                        this.ticket = ticket;
                        this.getCleanSelection();
                        this.$scroll.scrollTo(0);
                    }
                });
            android.betPlaced('jackpot');
        },
        getCleanSelection() {
            this.$store.commit(
                'sport/setJackpotSelected',
                this.pool.rows.map(() => [])
            ); // mutation BP-16141
            this.validate();
            this.resetError();
            this.$gtm.query({ event: 'jackpot_clear_selection', pollId: this.pool.id });
        },
        getRandomSelection() {
            this.$store.commit(
                'sport/setJackpotSelected',
                this.selected.map(() => this.getRandom())
            ); // mutation BP-16141
            this.validate();
            this.resetError();
            this.$gtm.query({ event: 'jackpot_random_selection', pollId: this.pool.id });
        },
        resetError() {
            if (this.isAuthenticated) {
                this.$store.dispatch(sportAction.SET_JACKPOT_ERROR, null);
            }
        },
        getRandom() {
            return [Math.floor(Math.random() * 3) + 1];
        },
        isActive(outcome, eventIndex) {
            return this.selected[eventIndex]?.includes(outcome);
        },
        selectOption(variation, outcome, eventIndex, event) {
            const isActive = this.isActive(outcome, eventIndex);
            const optionStatus = isActive ? 'active' : 'disabled';

            this.$store.commit(
                'sport/setJackpotSelected',
                this.selected.map((outcomes, i) => {
                    if (i === eventIndex) {
                        return isActive ? outcomes.filter((o) => o !== outcome) : [...outcomes, outcome];
                    }
                    return outcomes;
                })
            ); // mutation BP-16141
            this.validate();
            this.$gtm.query({
                event: 'jackpot_select_option',
                eventIndex,
                eventId: event.id,
                variation,
                optionStatus,
            });
        },
        validate() {
            let selectionValid = true;
            this.selected.map((item) => {
                if (!item.length) selectionValid = false;
            });
            this.valid = selectionValid;
        },
        createSelected() {
            if (this.selected.length === this.pool.events.length && Array.isArray(this.selected[0])) {
                return;
            }
            this.getCleanSelection();
        },
        getTiebreaker(id) {
            const { value } = this.tiebreakers.length ? this.tiebreakers.find((it) => it.id === id) : '';
            return value;
        },
        updateTiebreaker(payload = {}) {
            const { id, value } = payload;
            this.tiebreakers = (id ? this.tiebreakers : this.poolTiebreakers).map((it) => ({ ...it, ...(it.id === id && { value }) }));
        },
    },
};
</script>

<style scoped lang="scss">
.lucky-dip-slot {
    margin-bottom: 10px;
}

.jackpot-info {
    text-align: center;
    color: $event-pool-jackpot-info-color;
    cursor: pointer;
    padding: 0 10px;

    .icon-info {
        width: 18px;
        height: 18px;
    }
}
.button-lucky-dip {
    color: $event-pool-button-lucky-dip-color;
    background: $event-pool-button-lucky-dip-background;
}

.events {
    &-header {
        @extend %small-details-font-400;
        color: $grey-text;
        padding: 5px 12px;
        display: flex;
        text-align: left;

        .title {
            // TODO: remove in favor of global flex
            flex: 1;
        }
    }

    .flex {
        display: flex;
        align-items: center;
    }

    &-item {
        display: table;
        width: 100%;
        padding: 12px;
        background: $white-bg;
        border-bottom: 1px solid $event-pool-event-item-border-color;

        &-match {
            text-align: left;
            flex: 1;

            h3,
            h4 {
                margin: 0;
            }

            h3 {
                @extend %h4-font-700;
                line-height: 17px;
                color: $betpawa-black;
                text-transform: none;
                padding-bottom: 8px;
            }

            h4 {
                @extend %body-small-font-700;
                line-height: 1.2rem;
                color: $grey-text;
                font-weight: normal;
                padding: 0 0 8px;
            }
        }

        &-selection {
            &-wrapper {
                display: table;
                width: 100%;
                table-layout: fixed;
                height: 100%;
            }

            .flex & {
                flex: 1;
            }

            .cell {
                display: table-cell;
                vertical-align: middle;
                max-width: 0;

                &:not(:last-child) {
                    padding-right: 10px;
                }
            }

            .button {
                font-weight: normal;
                font-size: 12px;
                padding: 6px 12px;
                text-transform: none;
                color: $main-text;
                height: 100%;

                &.button-accent-default {
                    background: $betline-event-odds-background;
                    border: $betline-event-border;
                }
                &.pool-button-accent {
                    background: $betline-event-odds-background-selected;
                    color: $white-text;
                    border: $betline-event-border-selected;
                    font-weight: bold;
                }
                &-primary {
                    color: white;
                }
            }
        }
    }

    &-summary {
        @extend %body-big-font-400;
        border-top: 1px solid $line-color;
        padding: 5px 1.2rem;

        .row {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            padding: 0.6rem 0 0 0;
        }

        .notify.error {
            margin: 20px auto;
            padding: 12px;
        }
    }

    &-clear-selection {
        @extend %body-big-font-400;
        float: right;
        padding: 0 15px 15px 0;
    }
}

.tiebreaker {
    padding-bottom: 16px;

    &:last-child {
        padding-bottom: 4px;
    }
    &:not(:last-child) {
        margin-bottom: 16px;
        border-bottom: 1px solid $event-pool-event-item-border-color;
    }

    &-title {
        font-size: 12px;
        margin-bottom: 16px;
    }

    &-question {
        font-size: 14px;
        padding-right: 10px;
    }

    .input-field {
        min-width: 50px;
        width: 50px;
    }
}

.full-width {
    width: 100%;
}

.full-height {
    height: 100%;
}
</style>
