<template>
    <div class="menu-item" :class="{ selected, dark: isDark }">
        <router-link
            :to="link"
            :class="{ link: true }"
            :event="shouldRenderAsLink ? 'click' : ''"
            :tag="shouldRenderAsLink ? 'a' : 'div'"
            @click.native="onItemClick()"
        >
            <div class="table">
                <div class="row-cell align-middle details" @click="onIsLeftSideClick(true)">
                    <SvgIcon v-if="item.icon" :icon-id="item.icon" class="icon-status icon-size-medium" />
                    <slot v-else name="icon" />
                    <span class="menu-text name" :class="{ underline, capitalize: isCapitalizeMenuTitles }">{{ item.text }}</span>
                    <Badge v-if="item.badge" class="menu-text" :text="item.badge" :type="item.badgeType" :bg-color="item.badgeColor" />
                </div>
                <div
                    v-if="item.control && (item.control.text || item.control.icon)"
                    class="row-cell align-middle control"
                    @click="onIsLeftSideClick(false)"
                >
                    <span v-if="item.control.text" class="menu-text">{{ item.control.text }}</span>
                    <SvgIcon
                        v-if="!item.control.iconDisabled"
                        :icon-id="item.control.icon || 'arrow_right'"
                        :class="item.control.iconSize || 'icon-size-very-small'"
                    />
                </div>
            </div>
        </router-link>
        <slot name="content" />
    </div>
</template>

<script>
import Badge from '@/components/Badge.vue';

export default {
    name: 'MenuItem',
    components: { Badge },
    props: {
        link: {
            type: [String, Object],
            default: () => ({}),
        },
        item: {
            type: Object,
            default: () => ({}),
        },
        selected: {
            type: Boolean,
            default: false,
        },
        underline: {
            type: Boolean,
            default: false,
        },
        isCapitalizeMenuTitles: {
            type: Boolean,
            default: false,
        },
        isDark: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        shouldRenderAsLink() {
            return (this.link.path || this.link.name) && !this.link.isExternal;
        },
    },
    methods: {
        onIsLeftSideClick(isLeft) {
            this.$emit('onIsLeftSideClick', isLeft);
        },
        onItemClick() {
            this.$emit('onItemClick', this.item);
        },
    },
};
</script>

<style lang="scss" scoped>
.menu-item {
    background: white;
    position: relative;

    > .link {
        @extend %menu_item;
        padding: $left-menu-item-padding-vertical $left-menu-item-padding-horizontal;
    }

    &:not(:last-child) {
        > .link {
            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 12px;
                background-color: $left-menu-divider;
                width: calc(100% - 24px);
                height: 1px;
            }
        }
    }

    span:not(.menu-text) {
        display: block;
    }

    ::v-deep .icon-status,
    ::v-deep .icon-flag {
        vertical-align: middle;
        margin: 3px 8px 3px 3px;

        & + svg {
            margin-left: 12px;
        }
    }
    ::v-deep .menu-text + svg {
        margin-left: 12px;
    }

    & > .link:hover,
    & > .link:active {
        color: initial;
    }

    .details {
        display: flex;
        align-items: center;
    }

    .control {
        text-align: right;
        min-width: $left-menu-item-control-width;
    }

    &.selected:not(.sub-menu-item) > .link {
        border-left: $menu-item-border-width solid $menu-item-border-color;
        padding-left: 12px;
        color: $menu-item-color-selected;
        font-weight: bold;

        .name {
            text-decoration-color: $menu-item-color-selected;
        }

        @include oldschool {
            border-left: none;
        }

        svg.svg-icon {
            fill: $menu-item-color-selected;
        }
    }
    &.selected.sub-menu-item > .link {
        color: $menu-item-color-selected;
        font-weight: 500;
    }

    &.sub-menu-item {
        > .link {
            text-transform: none;
            padding-left: 38px;

            @include oldschool {
                padding-left: 12px;
            }
        }

        &,
        &.selected {
            text-transform: none;
        }

        &.selected > .link {
            border-left: none;
        }
    }

    &.sub-menu:has(.icon-flag) {
        .sub-menu-item .link {
            padding-left: 48px;
        }
    }
    &.sub-menu:has(.icon-status) {
        .sub-menu-item .link {
            padding-left: 38px;
        }
    }

    &.dark {
        > .link {
            text-transform: none;
            background-color: $left-menu-link-background;
            @extend %body-normal-font-400;

            .menu-text {
                color: $left-menu-link-secondary-color;

                &.name {
                    color: $left-menu-link-primary-color;
                }
            }

            &:after {
                background-color: $left-menu-divider-dark;
            }

            svg.svg-icon {
                fill: $left-menu-svg-color;
            }
        }

        &.selected:not(.sub-menu-item) > .link {
            display: flex;
            align-items: center;
            color: $left-menu-link-primary-color;
            background-color: $left-menu-link-selected-background;
            @extend %body-normal-font-500;

            .menu-text {
                color: $left-menu-link-primary-color;
            }

            svg.svg-icon {
                fill: $left-menu-svg-color;
            }
        }
        &.selected.sub-menu-item > .link {
            .menu-text {
                color: $menu-item-color-selected-dark;
            }
        }
    }
}
</style>
