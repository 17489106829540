<template>
    <div v-if="sports.errorCode === 'EVENTS_PRICING_GROUP_NOT_FOUND'" class="page-error">
        <ErrorPage
            :img-src="imgSrc"
            :button="{ text: $t('ui.common.goToUpcomingMatches'), emit: true }"
            :title="$t('ui.eventPage.error.title.pricingGroupNotFound')"
            :section-list="[$t('errors.EVENTS_PRICING_GROUP_NOT_FOUND')]"
            @button:click="buttonClick"
        />
    </div>
    <div v-else v-sniffer="{ handler: onSnifferLoadMore, distance: 0 }" class="sports-view">
        <Headline v-if="!showEventFilters && headlineText" :title="headlineText" :icon="$t(`project.sports.${currentCategoryId}.icon`)" />
        <Tabs
            v-if="!showEventFilters"
            class="market-groups-tabs"
            :tabs="marketGroups"
            :active="currentGroup"
            :align="tabsAlign"
            :hide-menu="showEventFilters"
            tab-type="text"
            @select="setMarketGroup($event)"
        />
        <div v-if="sports.error" class="page-error">
            <renderer :input="sports.error" />
        </div>
        <NoEvents v-else-if="noEvents" />
        <template v-else>
            <div v-for="event in eventsList" :key="event.Id">
                <Game :event="event" :show-market-count="true" :is-first-tab="isFirstTab" />
            </div>
        </template>
        <div class="spinner-wrapper">
            <Spinner
                :visible="isEventsLoading"
                class="align-top"
                :class="{
                    'viewport-padding': isEventsLoading && !eventsList.length,
                    'load-more-spinner': isEventsLoading && eventsList.length,
                }"
            />
        </div>
        <HotDescription v-if="sports.containsHot" />
    </div>
</template>

<script>
import { mapMutations, mapState, mapGetters, mapActions } from 'vuex';
import { helper } from '@/modules/core';
import { getter as coreGetter } from '@/modules/core/store/const';
import { getObjectField } from '@/modules/core/utils/helper';
import { Tabs } from '@/modules/core/components';
import { DEFAULT_EVENT_QUERY_AMOUNT, EventType, EventCategory, MAX_LIVE_EVENT_QUERY_AMOUNT } from '@/modules/sport';
import { action as sportAction, getter as sportGetter, mutation as sportMutation } from '@/modules/sport/store/const';
import { mutation as betslipMutation } from '@/modules/sport/store/modules/betslip/const';
import { getter as platformGetter } from '@/modules/platform/store/const';
import { buildEventsQueryParams } from '@/modules/sport/utils/api/build-events-query-params';

import HotDescription from '@/components/HotDescription.vue';
import PageMixin from '@/components/Pages/Page.mixin';
import SEOMixin from '@/components/Pages/SEO.mixin';

import Game from '../Game.vue';
import ErrorPage from '@/components/Pages/ErrorPage.vue';
import { ERROR_IMAGES } from '@/components/content/content-const';
import scrollSniffer from '@/js/directives/ScrollSniffer';
import { getter as generalGetter } from '@/store/const';
import { routeName } from '@/router/const-name';
import NoEvents from '@/modules/sport/components/Fragments/Event/NoEvents.vue';
import { EVENTS_SORTING_ACCEPTABLE_MAP } from '@/modules/sport/const/events-const';

export default {
    name: 'SportsView',
    components: { NoEvents, Game, HotDescription, Tabs, ErrorPage },
    directives: {
        sniffer: scrollSniffer,
    },
    mixins: [PageMixin, SEOMixin],
    props: {
        seoHandler: {
            type: Function,
            default: (payload) => payload,
        },
        tabsAligned: {
            type: Boolean,
            required: false,
            default: false,
        },
        skipSetSEOTags: {
            // UpcomingView doesn't call setSEOTags method
            type: Boolean,
            required: false,
            default: false,
        },
        showEventFilters: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            hash: '',
            paginationIndex: 0,
            currentCompetitionsPayload: [],
        };
    },
    computed: {
        ...mapState({
            tournaments: 'currentTournaments',
            countries: 'countries',
            sports: (state) => state.sport.sports,
        }),
        ...mapGetters({
            areLoading: coreGetter.ARE_LOADING,
            isLoading: coreGetter.IS_LOADING,
            currentCategoryId: generalGetter.GET_CURRENT_CATEGORY_ID,
            brandPreference: platformGetter.GET_BRAND_PREFERENCE,
            savedEventFilters: sportGetter.SAVED_EVENT_FILTERS,
            savedEventSorting: sportGetter.SAVED_EVENT_SORTING,
            marketTypeFilters: platformGetter.GET_MARKET_TYPE_FILTERS,
        }),
        savedCriteriaId() {
            return `${this.currentCategoryId}-${this.$route.name.toLowerCase()}`;
        },
        sorting() {
            return getObjectField(this.$route.query, 'sorting', '');
        },
        hasSorting() {
            return !!this.sorting && EVENTS_SORTING_ACCEPTABLE_MAP[this.sorting];
        },
        savedSorting() {
            if (this.$route.name === routeName.LIVE && this.savedEventSorting) {
                return getObjectField(this.savedEventSorting, this.savedCriteriaId);
            }
            return '';
        },
        tabsAlign() {
            if (this.tabsAligned) {
                return this.$mq.isSmall ? 'center' : 'left';
            }
            return undefined;
        },
        country() {
            const { id } = this.$route.params;
            if (!this.countries.length) return {};
            if (this.$route.name === routeName.GROUP) {
                return this.countries.find((country) => country.competitions.some((c) => c.id === id)) || {};
            }
            return this.countries.find((country) => country.region.id === id) || {};
        },
        eventType() {
            switch (this.$route.name) {
                case routeName.LIVE:
                    return EventType.LIVE;
                default:
                    return EventType.UPCOMING;
            }
        },
        pageInfo() {
            const { id } = this.$route.params;
            const { name: country, id: regionId } = getObjectField(this.country, 'region', {});
            const { name: league } = getObjectField(this.country, 'competitions', []).find((c) => c.id === id) || {};
            return { id, country, regionId, league };
        },
        marketGroups() {
            const currentFilters = this.marketTypeFilters.find(({ category }) => String(category) === String(this.currentCategoryId));
            return currentFilters
                ? currentFilters.filters
                      .map((filter) => ({
                          text: filter.name,
                          key: filter.marketType,
                          name: filter.name,
                          priority: filter.priority,
                      }))
                      .sort((a, b) => a.priority - b.priority)
                : [];
        },
        firstMarketGroup() {
            return this.marketGroups[0];
        },
        imgSrc() {
            return ERROR_IMAGES.errorNoMatchesMarket;
        },
        noEvents() {
            return (!this.eventsList.length || !this.marketGroups.length) && !this.isEventsLoading;
        },
        headlineText() {
            const { name } = this.$route;

            switch (name) {
                case routeName.COUNTRY: {
                    return this.country?.region?.name || '';
                }
                case routeName.GROUP: {
                    const { country, league } = this.pageInfo;
                    if (!country || !league) {
                        return '';
                    }
                    return `${country} - ${league}`;
                }
                case routeName.UPCOMING: {
                    const text = this.$t(`project.sports.${this.currentCategoryId}.text`);
                    const eventsText = this.$t('ui.upcoming.upcomingEvents');
                    return `${text} - ${eventsText}`;
                }
                default: {
                    return '';
                }
            }
        },
        isEventsLoading() {
            return this.isLoading(sportAction.GET_EVENTS);
        },
        isFirstTab() {
            return this.currentGroup.key === this.firstMarketGroup.key;
        },
        eventsList() {
            const { events } = this.sports || {};
            if (this.isFirstTab) {
                return events;
            }
            return events.filter((event) => !!event.markets.length);
        },
        availableEventFilters() {
            return this.showEventFilters ? this.savedEventFilters : {};
        },
        savedMarketId() {
            return getObjectField(this.availableEventFilters, `${this.savedCriteriaId}.marketId`);
        },
        savedCompetitions() {
            return getObjectField(this.availableEventFilters, `${this.savedCriteriaId}.competitions`);
        },
        allCompetitions() {
            return Array.isArray(this.countries) ? this.countries.flatMap(({ competitions }) => competitions.map(({ id }) => id)) : [];
        },
        currentGroup() {
            return this.marketGroups.find(({ name }) => name === this.$route.query.marketId) || this.firstMarketGroup;
        },
        isNavigatedBackByBackBtnClick() {
            return getObjectField(this.$route, 'meta.scrollSelector', '');
        },
        getSportViewEventsQueryParams() {
            if (this.$route.name === routeName.LIVE) {
                return buildEventsQueryParams({ ...this.getEventsQuery(true), competitions: this.currentCompetitionsPayload });
            }

            // if filtered upcoming page/country/group build 2 separate queries to get live events and upcoming events with any odds
            // Initially, apply the filter to fetch up to MAX_LIVE_EVENT_QUERY_AMOUNT of live events.
            // On scrolling to load more, fetch only upcoming events.
            const isEventFilterApplied = !!this.currentCompetitionsPayload.length;
            const canRouteDisplayLiveEvents = [routeName.UPCOMING, routeName.COUNTRY, routeName.GROUP].includes(this.$route.name);
            const shouldIncludeLiveEvents = this.paginationIndex === 0 && isEventFilterApplied && canRouteDisplayLiveEvents;
            if (shouldIncludeLiveEvents) {
                return [this.getEventsQuery(true, MAX_LIVE_EVENT_QUERY_AMOUNT), this.getEventsQuery(false)].map((eventQuery) =>
                    buildEventsQueryParams({ ...eventQuery, competitions: this.currentCompetitionsPayload })
                );
            }
            return buildEventsQueryParams({ ...this.getEventsQuery(false), competitions: this.currentCompetitionsPayload });
        },
    },
    watch: {
        '$route.query': {
            immediate: true,
            deep: true,
            handler({ marketId }) {
                if (!this.isNavigatedBackByBackBtnClick) {
                    this.clearEventList();
                }
                this.$store.dispatch(sportAction.RESET_SPORTS_ERROR);
                const { id } = this.$route.params;
                let payloadCompetitions = [];
                const queryParams = new URLSearchParams(window.location.search);
                const competitions = queryParams.get('competitions');

                this.validateSortingQuery();

                this.paginationIndex = this.eventsList.length ? this.eventsList.length / DEFAULT_EVENT_QUERY_AMOUNT - 1 : 0;

                if (this.$route.name === routeName.GROUP && id) {
                    payloadCompetitions.push(id);
                }
                if (this.$route.name === routeName.COUNTRY && id) {
                    const countryCompetitions = getObjectField(this.country, 'competitions', []).map((i) => i.id) || [];
                    payloadCompetitions = [...payloadCompetitions, ...countryCompetitions];
                }
                if (!marketId && !competitions) {
                    // don't use saved marketId for group/country, use main market instead
                    const upcomingMarketId = this.savedMarketId ?? this.firstMarketGroup.name;
                    const canUseSavedMarketFilter = ![routeName.GROUP, routeName.COUNTRY].includes(this.$route.name);
                    const queryCompetitions = payloadCompetitions.length ? payloadCompetitions.join(',') : this.savedCompetitions;

                    const routeConfig = {
                        name: this.$route.name,
                        params: this.$route.params,
                        query: {
                            marketId: canUseSavedMarketFilter ? upcomingMarketId : this.firstMarketGroup.name,
                            ...(queryCompetitions ? { competitions: queryCompetitions } : {}),
                            categoryId: this.currentCategoryId,
                            ...(this.savedSorting ? { sorting: this.savedSorting } : {}),
                        },
                    };

                    this.$router.replace(routeConfig);
                    return;
                }

                if (competitions) {
                    const competitionsArr = Array.isArray(competitions) ? competitions : competitions.split(',');
                    payloadCompetitions = helper.removeArrayDuplicates(payloadCompetitions, competitionsArr);
                }

                this.currentCompetitionsPayload = payloadCompetitions;
                this.getEventsOnRouteChange();
            },
        },
        pageInfo: {
            immediate: true,
            handler({ id, country, regionId, league }) {
                if (!this.skipSetSEOTags && (country || league)) {
                    this.setSEOTags(
                        {
                            meta: {
                                seo: {
                                    template: this.$route.name.toLowerCase(),
                                },
                            },
                        },

                        this.seoHandler({
                            id,
                            regionId,
                            league,
                            country,
                            sport: this.$t(`project.sports.${this.currentCategoryId}.text`, { indefinite: true }),
                        })
                    );
                }
            },
        },
        allCompetitions: {
            deep: true,
            immediate: true,
            handler(newAllCompetitions) {
                const savedCompetitionsArray = this.savedCompetitions?.length ? this.savedCompetitions.split(',') : [];
                const nonExistingCompetitions = savedCompetitionsArray.filter(
                    (savedCompetition) => !newAllCompetitions.includes(savedCompetition)
                );

                if (newAllCompetitions.length > 0 && nonExistingCompetitions.length > 0) {
                    this.updateSavedEventFilters({
                        filterId: this.savedCriteriaId,
                        filters: {
                            marketId: this.savedMarketId,
                            competitions: savedCompetitionsArray
                                .filter((competitionId) => !nonExistingCompetitions.includes(competitionId))
                                .join(','),
                        },
                    });

                    // trigger filters recalculation through route.query watcher
                    this.$router.push({ name: this.$route.name, query: { categoryId: this.currentCategoryId } });
                }
            },
        },
    },
    created() {
        if (!this.$route.meta?.scrollSelector) {
            this.$store.commit(sportMutation.UPDATE_SPORTS, { events: [] }); // fix for console errors after navigation by breadcrumbs
        }
    },
    methods: {
        ...mapMutations({
            clearSelectedEventId: betslipMutation.CLEAR_SELECTED_EVENT_ID,
            clearEventList: sportMutation.CLEAR_EVENT_LIST,
        }),
        ...mapActions({
            updateSavedEventFilters: sportAction.UPDATE_SAVED_EVENT_FILTERS,
            updateSavedEventSorting: sportAction.UPDATE_SAVED_EVENT_SORTING,
        }),
        getEventsQuery(isLive, take = DEFAULT_EVENT_QUERY_AMOUNT) {
            return {
                categories: [this.currentCategoryId],
                eventType: isLive ? EventType.LIVE : this.eventType,
                marketTypes: [this.currentGroup.key],
                take,
                page: this.paginationIndex,
                ...(!isLive && { hasOdds: true }),
                ...(this.$route.name === routeName.POPULAR && { onlyPopular: true }),
                ...(this.hasSorting ? { sort: this.extractSortObject(this.sorting) } : {}),
            };
        },
        extractSortObject(sorting) {
            const [sortField = '', sortOrder = ''] = sorting.split('_');
            return {
                [sortField]: sortOrder,
            };
        },
        validateSortingQuery() {
            const { sorting } = this.$route.query;

            if (sorting && !EVENTS_SORTING_ACCEPTABLE_MAP[sorting]) {
                this.$router.replace({
                    name: this.$route.name,
                });
            }
        },
        setMarketGroup(group) {
            this.$gtm.query({ event: 'changed_market_type', tab: group.text });
            const { competitions } = this.$route.query;
            this.$router.push({
                name: this.$route.name,
                params: this.$route.params,
                query: {
                    marketId: group.name,
                    categoryId: this.currentCategoryId,
                    competitions,
                },
            });
            this.clearSelectedEventId(); // copy from Upcoming view
        },
        buttonClick() {
            this.$router.push({ name: routeName.UPCOMING });
        },
        loadMore() {
            if (this.isEventsLoading || !this.sports.hasMoreEvents) return;
            this.paginationIndex++;

            return this.$store.dispatch(sportAction.GET_EVENTS, {
                rawQuery: this.getSportViewEventsQueryParams,
                eventCategory: EventCategory.EVENTS,
            });
        },
        onSnifferLoadMore() {
            this.loadMore();
        },
        returnToHome() {
            this.$router.push({ name: routeName.HOMEPAGE });
        },
        getEventsOnRouteChange() {
            if (this.isNavigatedBackByBackBtnClick) return;

            this.$scroll.scrollTo(0, 1);
            this.$store.dispatch(sportAction.GET_EVENTS, {
                rawQuery: this.getSportViewEventsQueryParams,
                eventCategory: EventCategory.EVENTS,
            });

            this.updateSavedEventFilters({
                filterId: this.savedCriteriaId,
                filters: {
                    marketId: this.currentGroup.name,
                    competitions: this.currentCompetitionsPayload.join(','),
                },
            });

            if (this.$route.name === routeName.LIVE) {
                this.updateSavedEventSorting({
                    sortingId: this.savedCriteriaId,
                    sorting: this.sorting,
                });
            }
        },
    },
};
</script>

<style scoped lang="scss">
.sports-view {
    display: block;
}

.load-more {
    margin: 1rem 0 2rem;
    text-align: center;
}

.scroll-down {
    margin: 10px;
}

.load-more-spinner {
    position: static;
    padding: 16px 0;
}

.spinner-wrapper {
    position: relative;
}

.market-groups-tabs {
    padding: 2px 0;
    background: $light-grey;
    border-bottom: 1px solid $border-color;

    ::v-deep .tabs-selector {
        font-weight: bold;
    }
}
</style>
