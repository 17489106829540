<template>
    <div ref="menuTiles" :class="{ scrollable, shadow: isShadow, 'end-of-scroll': endOfScroll }" class="menu-tiles">
        <MenuTile
            v-for="(menuItem, index) in items"
            :key="menuItem.pageId + '-tile-menu-' + index"
            :item="menuItem"
            :link="menuItem.link"
            :selected="menuItem.isSelected"
            :data-test-id="`${placeName}-menu-tile-${menuItem.pageId}-${index}`"
            @click.native="onMenuItemClick(menuItem)"
        />
    </div>
</template>

<script>
import MenuTile from '@/modules/core/components/atoms/MenuTile.vue';
import { helper } from '@/modules/core';

export default {
    name: 'MenuTiles',
    components: { MenuTile },
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        scrollable: {
            type: Boolean,
            default: false,
        },
        placeName: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            endOfScroll: false,
            isShadow: false,
        };
    },
    mounted() {
        if (this.scrollable) {
            this.setCheckEndOfScroll();
            this.$refs.menuTiles.addEventListener('scroll', this.setCheckEndOfScroll);
        }
    },
    beforeDestroy() {
        if (this.scrollable) {
            this.$refs.menuTiles.removeEventListener('scroll', this.setCheckEndOfScroll);
        }
    },
    methods: {
        onMenuItemClick(menuItem) {
            if (menuItem.externalUrl) {
                this.trackMainNav(menuItem.pageId);
                helper.openUrl(menuItem.externalUrl, menuItem.target);
            } else {
                this.$emit('onMenuItemClick', menuItem.pageId);
            }
        },
        trackMainNav(element, event = 'main_nav') {
            if (element) {
                this.$gtm.query({
                    event,
                    click_text: element,
                });
            }
        },
        setCheckEndOfScroll() {
            const menuTiles = this.$refs.menuTiles;
            const scrollHeight = menuTiles.scrollHeight;
            const clientHeight = menuTiles.clientHeight;
            const scrollTop = menuTiles.scrollTop;
            const tolerance = 1;
            this.endOfScroll = clientHeight + scrollTop >= scrollHeight - tolerance;
            this.isShadow = scrollHeight >= 220;
        },
    },
};
</script>

<style scoped lang="scss">
.menu-tiles {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(55px, 1fr));
    gap: 12px 16px;
    flex-wrap: wrap;
    padding: 16px;
    column-gap: 16px;
    row-gap: 12px;
    background-color: $left-menu-category-color;
    border-radius: 4px;
    scrollbar-width: none;

    @include xxmaintofull {
        grid-template-columns: repeat(auto-fill, minmax(66px, 1fr));
    }
    &.scrollable {
        max-height: 220px;
        overflow-y: auto;
        container-type: inline-size;

        &.shadow {
            &:after {
                content: '';
                position: absolute;
                width: 100%;
                height: 50px;
                background: linear-gradient(180deg, $navigation-menu-shadow 0%, $navigation-menu-background-color-dark 100%);
                left: 0;
                bottom: 38px;
                opacity: 1;
                transition: opacity 0.1s linear;
                pointer-events: none;
            }

            &.end-of-scroll:after {
                opacity: 0;
            }
        }
    }
}
</style>
