var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:( _obj = {
        tabs: true
    }, _obj[_vm.tabType] = true, _obj.scrollable = _vm.scrollable, _obj.dark = _vm.isDark, _obj['with-bottom-border'] =  _vm.bottomBorder, _obj['with-top-border'] =  _vm.topBorder, _obj['have-scroll'] =  _vm.tabsHaveScroll, _obj['end-of-scroll'] =  _vm.endOfScroll, _obj['truncate-text'] =  _vm.truncateText, _obj )},[_c('ul',{ref:"tabsMenu",class:( _obj$1 = {
            'tabs-menu': true
        }, _obj$1['align-' + _vm.align] = true, _obj$1 )},_vm._l((_vm.tabs),function(tab,index){
        var _obj;
return _c('li',{key:tab.key + index,ref:("tab-" + (tab.key)),refInFor:true,staticClass:"tabs-selector",class:( _obj = {
                active: tab.key === _vm.active.key && !tab.disableHighlight,
                first: index === 0,
                last: index === _vm.tabs.length - 1
            }, _obj['no-grow'] = (!tab.text || tab.isTextHidden) && !tab.disableNoGrow, _obj.highlight = tab.highlight, _obj[tab.sticky] = tab.sticky, _obj ),attrs:{"data-test-id":("tabs-" + (tab.key))},on:{"click":function($event){return _vm.handleTabClick(tab)}}},[_c('div',{staticClass:"tab-item"},[_c('div',{staticClass:"tab-item-border"},[(tab.componentName)?[_c(tab.componentName,_vm._b({tag:"component"},'component',tab.props,false))]:[(tab.iconId)?_c('div',{staticClass:"tab-icon"},[_c('div',{class:['om-icon-wrapper', tab.iconClass]},[_c('SvgIcon',{staticClass:"icon",class:tab.iconClass,attrs:{"icon-id":tab.iconId}})],1)]):_vm._e(),_vm._t("tab-prefix",null,{"tab":tab}),(tab.text && !tab.isTextHidden)?_c('span',{staticClass:"tab-text"},[_vm._v(_vm._s(tab.text))]):_vm._e(),(tab.count)?_c('span',{class:{ 'tab-counter': true, active: tab.key === _vm.active.key }},[_vm._v(" "+_vm._s(tab.count)+" ")]):_vm._e(),_vm._t("tab-suffix",null,{"tab":tab})]],2)])])}),0),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }