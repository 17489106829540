// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import Vuex from 'vuex';
import { filters } from '@/modules/core';

import App from './App.vue';
import router from './router';
import store from './store/store';
import 'intersection-observer/intersection-observer'; // Polyfill for IntersectionObserver
import './js/directives';
import './js/plugins';
import './js/globalComponents';
import './js/polyfills';

Vue.config.productionTip = false;
Vue.use(Vuex);
Vue.use(filters.Currency);

const app = new Vue({
    el: '#app',
    store,
    router,
    components: { App },
    template: '<App />',
    mq: {
        // `mt` prefix for mobile toolbar links
        mtVerySmall: '(max-width: 260px)',
        mtSmall: '(min-width: 260px) and (max-width: 350px)',
        mtMedium: '(min-width: 350px) and (max-width: 420px)',
        mtLarge: '(min-width: 420px) and (max-width: 600px)',

        // from `xxs` - `vxs` are feature phone media queries
        vxs: '(max-width: 219px)',
        ivxs: '(min-width: 220px) and (max-width: 279px)',
        xxxs: '(min-width: 280px) and (max-width: 339px)',
        xxs: '(min-width: 340px) and (max-width: 379px)',

        // from `xs` - `xl` are standard mobile media queries
        xs: '(min-width: 380px) and (max-width: 599px)', // small to large handset          380px < 600px
        sm: '(min-width: 600px) and (max-width: 959px)', // large handset to medium tablet  600px > < 960px
        md: '(min-width: 960px)', // large tablet                                           960px >

        // from `xxs` - `vxs` are feature phone media queries
        vxsUp: '(min-width: 219px)',
        ivxsUp: '(min-width: 220px)',
        xxxsUp: '(min-width: 280px)',
        xxsUp: '(min-width: 340px)',

        // from `xs` - `xl` are standard mobile media queries
        xsUp: '(min-width: 380px)', //  up to small            380px
        smUp: '(min-width: 600px)', //  up to large handset    600px
        mdUp: '(min-width: 960px)', //  up to medium tablet    960px

        // css-unbalanced media queries TODO: migrate to standard media queries above
        isVerySmallMin: '(min-width: 241px)',
        isVerySmall: '(max-width: 280px)',
        isXXSmallMin: '(min-width: 281px)',
        isXXSSmallMin: '(min-width: 340px)',
        isXXSmall: '(max-width: 340px)',
        isXSmallMin: '(min-width: 341px)',
        isXSmall: '(max-width: 360px)',
        isXSSmallMin: '(min-width: 361px)',
        isXSSmall: '(max-width: 380px)',
        isSSSmallMin: '(min-width: 381px)',
        isSSSmall: '(max-width: 420px)',
        isSSmallMin: '(min-width: 420px)',
        isSSmall: '(max-width: 480px)',
        isSmallMin: '(min-width: 481px)',
        isSmall: '(max-width: 520px)',
        isBeforeSmallMin: '(min-width: 521px)',
        isBeforeSSmall: '(max-width: 600px)',
        isBeforeSmall: '(max-width: 610px)',
        isMediumMin: '(min-width: 611px)',
        isMedium: '(max-width: 640px)',
        isXMediumMin: '(min-width: 641px)',
        isXMedium: '(max-width: 767px)',
        isXXMediumMin: '(min-width: 768px)',
        isXXMedium: '(max-width: 960px)',

        // for screens with high dpi
        isHighRes: '(-webkit-min-device-pixel-ratio: 2), (min-device-pixel-ratio: 2)', // , (min-resolution: 192dpi)
    },
});

export default app;
