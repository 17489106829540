<template>
    <div>
        <div v-if="$mq.isXMedium" class="mobile-betslip-header">
            <div v-if="isAuthenticated" class="mobile-betslip-header-content">
                <DropDown :options="tabs" class="dropdown" @change="handleSelect" />
                <div class="balance">
                    <span>{{ $t('ui.balance.balance') }}:</span>
                    <span v-if="currency" :class="automationConst.count">
                        {{ balance | currency(currency.format) }}
                    </span>
                </div>
            </div>
            <div v-else class="mobile-betslip-header-content">
                <div class="mobile-betslip-header-title">
                    <span class="mobile-betslip-header-title-icon">
                        <SvgIcon class="icon icon-size-medium" icon-id="icon-statement" vertical-align="top" />
                    </span>
                    {{ $t('ui.openBetslip.sportsBetslip') }}
                </div>
                <renderer :input="$t('ui.balance.joinNowOrLogin')" />
            </div>
            <div class="betslip-close" @click="hideBetslip()">
                <SvgIcon class="icon icon-size-medium" icon-id="icon-close" />
            </div>
        </div>
        <div v-else class="betslip-header">
            <div v-if="isAuthenticated" class="betslip-header-content">
                <div class="betslip-header-link">
                    <div class="view-my-bets" @click="viewMyBetsGTM">
                        <SvgIcon class="icon icon-size-small" icon-id="icon-arrow-down" />
                        <router-link :to="{ name: 'My Bets' }" class="view-my-bets-link">
                            {{ $t('ui.betslip.viewMyBets') }}
                        </router-link>
                    </div>
                </div>
                <div class="balance">
                    <span class="betslip-header-title">
                        <label>{{ $t('ui.balance.yourBalance') }}</label>
                    </span>
                    <span v-if="currency" :class="automationConst.count">
                        {{ balance | currency(currency.format) }}
                        <template v-if="!balance">
                            -
                            <router-link class="underline" :to="{ path: '/deposit' }">{{ $t('ui.balance.howToDeposit') }} </router-link>
                        </template>
                    </span>
                </div>
            </div>
            <div v-else class="betslip-header-content">
                <renderer class="betslip-header-info" :input="$t('ui.balance.notLoggedIn') + $t('ui.balance.joinNowOrLogin')" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { getter as authGetter } from '@/modules/platform/store/modules/auth/const';

import { action } from '@/store/const';
import automationConstants from '@/js/automation-const';
import DropDown from './DropDown.vue';

export default {
    name: 'BetslipHeader',
    emit: ['onSelect'],
    components: { DropDown },
    props: {
        tabs: Array,
    },
    computed: {
        ...mapState({
            currency: (state) => state.platform.settings.currency,
        }),
        ...mapGetters({
            balance: authGetter.GET_BALANCE,
            isAuthenticated: authGetter.IS_AUTHENTICATED,
        }),
        isBetslipHidden: {
            get() {
                return this.$store.state.ui.betslipOpen;
            },
            set(newValue) {
                this.$store.dispatch(action.SET_BETSLIP_STATE, newValue);
            },
        },
        automationConst() {
            return automationConstants;
        },
    },
    methods: {
        hideBetslip() {
            this.isBetslipHidden = !this.isBetslipHidden;
            this.$gtm.query({ event: 'bet_slip_hide' });
        },
        handleSelect(option) {
            this.$emit('onSelect', option);
        },
        viewMyBetsGTM() {
            this.$gtm.query({ event: 'betslip_view_my_bets' });
        },
    },
};
</script>

<style scoped lang="scss">
.betslip-header {
    display: flex;
    align-items: center;
    text-align: left;
    padding: 12px;
    min-height: 63px;
    @extend %body-normal-font-400;

    &-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    &-link {
        flex: 0 1 55%;
        display: flex;
    }

    .balance {
        flex: 0 1 45%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-wrap: wrap;
        text-align: right;

        .count {
            @extend %body-normal-font-700;
            margin-left: 4px;
        }
    }

    .betslip-close {
        margin-right: 12px;
        position: absolute;
        right: 0;
        cursor: pointer;

        svg {
            padding: 5px;
            width: 22px;
            height: 22px;
        }
    }

    &-title {
        label {
            @extend %body-small-font-400;
        }
    }

    &-info {
        margin: 5px 0 5px;
    }
}

.mobile-betslip-header {
    display: flex;
    align-items: center;
    border-bottom: $header-mobile-border;
    background: $header-mobile-background-color;
    color: $light-grey;
    min-height: 40px;
    padding: 14px 12px;

    &-content {
        flex: 1;
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        line-height: 15px;
        margin-right: 10px;
    }

    &-title {
        display: flex;
        align-items: center;

        &-icon {
            margin-right: 4px;

            svg {
                fill: #aaaeb0;
            }
        }
    }

    @include maxbasic {
        padding: 19px 12px;
        align-items: flex-start;

        &-content {
            flex-direction: column;
        }

        .dropdown,
        &-title {
            margin-bottom: 8px;
        }
    }

    .betslip-close {
        margin-top: -4px;
        padding: 4px;

        .icon {
            fill: white;
        }
    }
}

.view-my-bets {
    display: flex;
    align-items: center;
    cursor: pointer;

    .icon {
        margin-right: 8px;
        transform: rotate(90deg);
    }

    &-link {
        @extend %link-regular-font;
        line-height: 17px;
        user-select: none;
        text-decoration: underline;
    }

    &:hover svg {
        color: $primary-color;
        fill: $primary-color;
    }

    &:hover &-link {
        color: $primary-color;
    }
}
</style>
