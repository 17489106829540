<template>
    <div v-click-outside:[true]="hideLeftMenu" class="lmenu-main">
        <LeftMenuHeader @hideLeftMenu="hideLeftMenu" />
        <MenuTiles :items="leftMenu" place-name="leftMenu" @onMenuItemClick="hideLeftMenu" />
        <LeftMenuCategories @updatedCategory="updateCategory" />
        <TournamentsList :title="$t('ui.lMenu.leagues')" is-dark @onMenuItemClick="hideLeftMenu" />
        <CountryMenus is-dark @onMenuItemClick="hideLeftMenu" />
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { helper } from '@/modules/core';

import { getter as platformGetter } from '@/modules/platform/store/const';
import { getter as authGetter } from '@/modules/platform/store/modules/auth/const';
import { getter as translationsGetter } from '@/store/modules/translations/const';
import { getter as sportGetter } from '@/modules/sport/store/const';

import { action, getter as generalGetter } from '@/store/const';
import { CountryMenus, TournamentsList } from '@/modules/sport/components';
import { routeName } from '@/router/const-name';
import LeftMenuHeader from '@/components/Fragments/LeftMenuHeader.vue';
import MenuTiles from '@/modules/core/components/atoms/MenuTiles.vue';
import { getObjectField } from '@/modules/core/utils/helper';

const LeftMenuCategories = () => import('./LeftMenuCategories');

const MENU_ICONS = {
    homepage: 'icon-home',
    live: 'icon-live',
    casino: 'icon-casino',
    popular: 'icon-popular',
    jackpotpage: 'icon-jackpot',
    upcoming: 'icon-clock',
    'virtual sports': 'icon-virtual-sport',
    pawa6: 'icon-pawa6',
};

const CASINO_PAGE_ID = 'Casino';

export default {
    name: 'LeftMenu',
    components: { MenuTiles, LeftMenuHeader, LeftMenuCategories, CountryMenus, TournamentsList },
    data() {
        return {
            menuIcons: MENU_ICONS,
            currentRoute: {
                name: '',
                id: null,
                category: null,
            },
            routeName,
            updatedCategory: null,
        };
    },
    computed: {
        ...mapState({
            strapiMenuLinks: (state) => helper.getObjectField(state.platform.config.leftMenu, 'links', []),
            setting: (state) => state.platform.settings,
        }),
        ...mapGetters({
            casinoName: translationsGetter.CASINO_NAME,
            isAuthenticated: authGetter.IS_AUTHENTICATED,
            quickMainMenu: generalGetter.GET_QUICK_MAIN_MENU,
            currentUserStatus: platformGetter.GET_CURRENT_USER_STATUS,
            currentCategoryId: generalGetter.GET_CURRENT_CATEGORY_ID,
            eventsCount: sportGetter.EVENTS_COUNT_PER_TYPE,
        }),
        hasStrapiMenu() {
            return !!this.strapiMenuLinks.length;
        },
        leftMenu() {
            const menu = this.hasStrapiMenu
                ? helper.processStrapiMenuLinks({
                      links: this.strapiMenuLinks,
                      userStatus: this.currentUserStatus,
                      isAuthenticated: this.isAuthenticated,
                      transformer: (link) => ({ ...link, url: link.externalUrl || link.path }),
                      router: this.$router,
                  })
                : this.quickMainMenu.filter((menuItem) => menuItem.isLeftMenuLink && this.shouldDisplay(menuItem));

            return menu.map((item) => {
                return {
                    ...item,
                    badge: this.hasStrapiMenu ? item.badge : this.$t(item.badge),
                    badgeColor: this.hasStrapiMenu ? item.badgeColor : '',
                    icon: this.hasStrapiMenu ? item.icon : this.menuIcons[item.pageId.toLowerCase()],
                    text: this.hasStrapiMenu ? item.name : this.$t(item.key, { casinoName: this.casinoName }),
                    count: this.getMenuItemCount(item.pageId),
                    link: {
                        path: item.url,
                        query: item.query,
                        isExternal: !!item.externalUrl,
                    },
                    hasStrapiMenu: this.hasStrapiMenu,
                    isSelected: this.hasStrapiMenu
                        ? this.isCurrentMenuItem(item) && !item.disableHighlight
                        : this.currentRoute.name === item.pageId,
                };
            });
        },
        sportPagesEventsCount() {
            return {
                [routeName.UPCOMING]: this.eventsCount.upcomingEventsCount,
                [routeName.LIVE]: this.eventsCount.liveEventsCount,
                [routeName.POPULAR]: this.eventsCount.popularEventsCount,
                [routeName.BOOSTED_EVENTS]: this.eventsCount.boostedEventsCount,
            };
        },
    },
    watch: {
        $route: {
            immediate: true,
            handler(to) {
                this.$data.currentRoute = { name: to.name, id: to.params.id, category: to.query.category };
            },
        },
    },
    methods: {
        $getObjectField: helper.getObjectField,
        shouldDisplay(menuItem) {
            const isEnabled = !menuItem.enabler || helper.pathChecker(menuItem.enabler, this.setting);
            return isEnabled && this.isCasinoItemShown(menuItem);
        },
        isCasinoItem(menuItem) {
            return menuItem.pageId === CASINO_PAGE_ID;
        },
        isCasinoItemShown(menuItem) {
            return !this.isCasinoItem(menuItem);
        },
        hideLeftMenu(element) {
            this.$store.dispatch(action.SET_SIDEBAR_STATE, false);
            if (element) {
                this.trackMainNav(element);
                return;
            }

            const hasCategoryInUrl = this.$route.query.categoryId;
            const isCategoryChanges = this.updatedCategory && hasCategoryInUrl && this.updatedCategory !== hasCategoryInUrl;

            if (this.sportPagesEventsCount[this.$route.name] === undefined && this.updatedCategory) {
                this.$router.push({ name: routeName.UPCOMING, params: { categoryId: this.updatedCategory } });
                return;
            }
            if (isCategoryChanges) {
                this.$router.push({ params: { categoryId: this.updatedCategory } });
            }
        },

        trackMainNav(element, event = 'main_nav') {
            if (element) {
                this.$gtm.query({
                    event,
                    click_text: element,
                });
            }
        },
        isCurrentMenuItem(menuItem) {
            const isHomePage = this.$route.path === '/';
            if (isHomePage) {
                return menuItem.url === this.$route.path;
            }
            const page = this.$route.path
                .split('/')
                .filter((segment) => !!segment)
                .pop();
            return menuItem.links.includes(page);
        },
        getMenuItemCount(page) {
            return { text: getObjectField(this.sportPagesEventsCount[page], String(this.currentCategoryId)) };
        },
        updateCategory(category) {
            this.updatedCategory = category;
        },
    },
};
</script>

<style scoped lang="scss">
.lmenu-main {
    position: relative;
    overflow: auto;
    background-color: $dark-green;
    padding: $left-menu-padding;
}

.menu-link {
    display: block;
    padding: 12px;
    margin: 0;
}

::v-deep .sub-menu {
    & > .link,
    &.selected > .link {
        background: #f5f5f0;
    }
}

.close {
    cursor: pointer;
    padding-left: 10px;

    svg {
        padding: 5px;
        width: 22px;
        height: 22px;
    }
}

.slide-enter-active,
.slide-leave-active {
    transition: max-height 0.4s, opacity 0.4s;
    max-height: 500px;
    overflow: hidden;
}

.slide-enter,
.slide-leave-to {
    opacity: 0;
    max-height: 0;
}
</style>
