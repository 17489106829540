<template>
    <div>
        <Spinner :visible="fetchingRegions" class="align-center inset viewport-padding" />
        <form v-if="!fetchingRegions" class="join-now" @submit.prevent>
            <renderer v-if="!inProgress && error" class="notify error" :input="error" />
            <div v-if="$v && $v.form.idType && $v.form.idType.$dirty && $v.form.idType.$error" class="notify error">
                {{ $t('ui.registration.error.pickIdType') }}
            </div>
            <template v-for="field in validationScenariosFields">
                <PhoneNumber
                    v-if="field.fieldName === signupFieldType.PHONE_NUMBER"
                    :key="field.fieldName"
                    :form-name="formNameId"
                    :value="form.phoneNumber"
                    :help-text="$t(`ui.countries.${jurisdictionCountry}.form.help.phoneNumber`, { indefinite: true })"
                    :v="$v.form.phoneNumber"
                    v-bind="{
                        ...(phoneNumberValidation && {
                            placeholder: phoneNumberValidation.placeholder,
                            errorMessages: { phoneNumberFormat: $t(phoneNumberValidation.errorMessageKey) },
                        }),
                    }"
                    @value="form.phoneNumber = $event"
                />
                <Password
                    v-if="field.fieldName === signupFieldType.PASSWORD"
                    :key="field.fieldName"
                    :form-name="formNameId"
                    :value="form.password"
                    autocomplete="new-password"
                    :v="$v.form.password"
                    @value="form.password = $event"
                />
                <InputField
                    v-if="field.fieldName === signupFieldType.FIRST_NAME"
                    :key="field.fieldName"
                    name="firstName"
                    :form-name="formNameId"
                    :value="form.firstName"
                    :attrs="{ maxLength: 40 }"
                    :v="$v.form.firstName"
                    :error-messages="errorMessages.firstName"
                    @value="form.firstName = $event"
                >
                    <template slot="label">{{ $t('ui.common.user.firstName') }}</template>
                </InputField>
                <InputField
                    v-if="field.fieldName === signupFieldType.LAST_NAME"
                    :key="field.fieldName"
                    name="lastName"
                    :form-name="formNameId"
                    :value="form.lastName"
                    :attrs="{ maxLength: 40 }"
                    :v="$v.form.lastName"
                    :error-messages="errorMessages.lastName"
                    @value="form.lastName = $event"
                >
                    <template slot="label">{{ $t('ui.common.user.lastName') }}</template>
                </InputField>
                <DOB
                    v-if="field.fieldName === signupFieldType.DATE_OF_BIRTH"
                    :key="field.fieldName"
                    name="dob"
                    :form-name="formNameId"
                    @value="form.dob = $event"
                />
                <template v-if="field.fieldName === signupFieldType.REGION_ID">
                    <label :key="`${field.fieldName}-label`" :for="`${formNameId}-location`" class="form">
                        {{ $t('ui.common.user.location') }}
                    </label>
                    <select
                        :id="`${formNameId}-location`"
                        :key="`${field.fieldName}-region`"
                        v-model="form.location"
                        type="text"
                        name="location"
                        class="form global-select"
                        :class="[$v && $v.form.location.$dirty && ($v.form.location.$error ? 'error' : 'valid')]"
                        @change="$v.form.location.$touch()"
                    >
                        <option value="">{{ $t('ui.common.select') }} {{ $t(`project.ui.region.${jurisdictionCountry}`) }}</option>
                        <option v-for="region of regions" :key="region.id" :value="region.id">{{ region.name }}</option>
                    </select>
                </template>
                <template v-if="field.fieldName === signupFieldType.NATIONALITY">
                    <label :key="`${field.fieldName}-label`" :for="`${formNameId}-nationality`" class="form">
                        {{ $t(`ui.countries.${jurisdictionCountry}.form.label.nationality`) }}
                    </label>
                    <select
                        :id="`${formNameId}-nationality`"
                        :key="field.fieldName"
                        v-model="form.nationality"
                        name="nationality"
                        type="text"
                        class="form global-select"
                        :class="[$v && $v.form.nationality.$dirty && ($v.form.nationality.$error ? 'error' : 'valid')]"
                        @change="onNationalityChange"
                    >
                        <option v-for="(nationality, index) of nationalities" :key="`${formNameId}-option-${index}`" :value="nationality">
                            {{ nationality }}
                        </option>
                    </select>
                </template>
                <template v-if="field.fieldName === signupFieldType.IDENTIFICATION_TYPE">
                    <label
                        v-show="!idNumberField.hidden"
                        :key="`${field.fieldName}-label-idType`"
                        :for="`${formNameId}-idType`"
                        class="form"
                    >
                        {{ $t('ui.payment.payout.ghana.idType') }}
                    </label>
                    <select
                        v-show="!idNumberField.hidden"
                        :id="`${formNameId}-idType`"
                        :key="`${field.fieldName}-idType`"
                        v-model="form.idType"
                        type="text"
                        name="idType"
                        :disabled="idNumberField.locked"
                        class="form warn global-select"
                        :class="[$v && $v.form.idType.$dirty && ($v.form.idType.$error ? 'error' : 'valid')]"
                        @change="$v.form.idType.$touch()"
                    >
                        <option :value="null" hidden>
                            {{ $t('ui.payment.payout.ghana.selectType') }}
                        </option>
                        <option v-for="({ key }, index) in idTypeOptions" :key="`${key}-${index}`" :value="key">
                            {{ $t(`ui.countries.${jurisdictionCountry}.form.label.${key}`) }}
                        </option>
                    </select>
                </template>
                <template v-if="field.fieldName === signupFieldType.IDENTIFICATION_NUMBER">
                    <InputField
                        :key="`${field.fieldName}-idNumber`"
                        name="idNumber"
                        type="text"
                        :form-name="formNameId"
                        :value="form.idNumber"
                        :attrs="{
                            autocomplete: 'off',
                            ...(idNumberField.maxLength && { maxLength: idNumberField.maxLength }),
                        }"
                        :help-text="form.idType && $t(`ui.countries.${jurisdictionCountry}.form.help.${form.idType}`, { indefinite: true })"
                        :v="$v.form.idNumber"
                        :error-messages="errorMessages.idNumber"
                        @value="onIdNumberChange"
                    >
                        <template slot="label">
                            {{
                                $t(`ui.countries.${jurisdictionCountry}.form.label.${idNumberField.key}`, { indefinite: true }) + ' Number'
                            }}
                        </template>
                        <template v-if="form.idType === 'GHANA_CARD'">
                            <div class="ghana-id">GHA</div>
                        </template>
                    </InputField>
                </template>
            </template>
            <div class="column-wrapper">
                <CaptchaWidget v-if="showCaptcha" class="captcha-widget" :name="$options.name" />
                <input
                    type="submit"
                    class="button button-submit button-full"
                    :value="$t('ui.common.joinNow').toUpperCase()"
                    :disabled="isSubmitDisabled"
                    @click="register()"
                />
                <div class="terms" :class="{ 'terms-horizontal-center': !brandPreference.isNeedToAcceptTerms }">
                    <Checkbox
                        class="terms-checkbox"
                        :checked="!brandPreference.isNeedToAcceptTerms"
                        :v="brandPreference.isNeedToAcceptTerms && $v.isAgreementAccepted"
                        :error-messages="errorMessages.isAgreementAccepted"
                        :disabled="!brandPreference.isNeedToAcceptTerms"
                        @onCheck="onTermsCheckChange"
                    >
                        <div class="terms-text">
                            <template v-if="termsAndConditions">
                                <RichText :data="termsAndConditionsContent" />
                            </template>
                            <template v-else>
                                {{ agreementText }}
                                <router-link :to="{ path: '/terms' }" class="underline margin-top bold">
                                    {{ $t('ui.registration.termsAndConditions') }}
                                </router-link>
                            </template>
                        </div>
                    </Checkbox>
                </div>
            </div>

            <span class="login-text">
                {{ $t('ui.common.user.loginText') }}
                <router-link
                    :to="{ name: 'Login' }"
                    :event="isRouterTabLink ? 'click' : ''"
                    :tag="isRouterTabLink ? 'a' : 'span'"
                    class="underline bold"
                    @click.native="trackLinkClick"
                >
                    {{ $t('ui.common.user.login') }}
                </router-link>
            </span>
        </form>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { required, minLength, numeric } from 'vuelidate/lib/validators';
import { helper, VALIDATORS, NATIONALITIES } from '@/modules/core';
import { getter as coreGetter } from '@/modules/core/store/const';
import { InputField, Spinner, DOB } from '@/modules/core/components';
import { VERIFICATION_ERRORS } from '@/modules/platform';
import { action as generalAction, getter as platformGetter } from '@/modules/platform/store/const';
import { getter as authGetter, action as authAction, mutation as authMutation } from '@/modules/platform/store/modules/auth/const';

import PhoneNumber from '@/components/PhoneNumber.vue';
import Password from '@/components/Password.vue';
import Checkbox from '@/components/Checkbox.vue';
import { routeName } from '@/router/const-name';
import { user as userEndpoints } from '@/modules/platform/endpoints';
import RichText from '@/components/content/RichText.vue';
import { getObjectField } from '@/modules/core/utils/helper';

const signupFieldType = {
    PHONE_NUMBER: 'phoneNumber',
    PASSWORD: 'password',
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    DATE_OF_BIRTH: 'dateOfBirth',
    REGION_ID: 'regionId',
    NATIONALITY: 'nationality',
    IDENTIFICATION_NUMBER: 'identificationNumber',
    IDENTIFICATION_TYPE: 'identificationType',
};
const idTypeOptionsByJurisdiction = {
    GH: [
        { key: 'NON_NATIONAL', suffix: '_LABEL', nonNational: true, locked: true, maxLength: 30 },
        { key: 'GHANA_CARD', maxLength: 30 },
        { key: 'VOTER', maxLength: 10 },
        { key: 'PASSPORT', maxLength: 8 },
        { key: 'DRIVER_LICENCE', maxLength: 18 },
        { key: 'SSNIT', maxLength: 13 },
        { key: 'OLD_VOTER', maxLength: 10 },
    ],
    UG: [
        { key: 'NON_NATIONAL', nonNational: true, hidden: true, maxLength: 30 },
        { key: 'NIN', hidden: true, maxLength: 14 },
    ],
};
export default {
    name: 'JoinNowForm',
    components: { RichText, InputField, PhoneNumber, Password, Spinner, DOB, Checkbox },
    props: {
        isRouterTabLink: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            formNameId: 'registration-form',
            form: {
                phoneNumber: null,
                password: '',
                firstName: '',
                lastName: '',
                location: '',
                idNumber: '',
                idType: null,
                nationality: '',
                dob: '',
            },
            isAgreementAccepted: false,
            signupFieldType,
        };
    },
    validations() {
        const { isNeedToAcceptTerms, pin: isPin } = this.brandPreference;
        const validators = {
            form: {
                phoneNumber: {
                    required,
                    ...(this.phoneNumberValidation && { phoneNumberFormat: this.phoneNumberValidation.validator }),
                },
                password: {
                    required,
                    minLength: minLength(4),
                    ...(isPin && { numeric }),
                },
            },
        };
        if (isNeedToAcceptTerms) {
            validators.isAgreementAccepted = {
                isAccepted: (val) => val,
            };
        }
        for (const field of this.validationScenariosFields) {
            switch (field.fieldName) {
                case signupFieldType.FIRST_NAME:
                    validators.form.firstName = { name: VALIDATORS.name };
                    break;
                case signupFieldType.LAST_NAME:
                    validators.form.lastName = { name: VALIDATORS.name };
                    break;
                case signupFieldType.NATIONALITY:
                    validators.form.nationality = { required };
                    break;
                case signupFieldType.IDENTIFICATION_NUMBER:
                    const idNumber =
                        this.form.idType && (VALIDATORS[`${this.jurisdictionCountry}_${this.form.idType}`] || VALIDATORS[this.form.idType]);
                    validators.form.idNumber = {
                        ...(idNumber && { idNumber }),
                        required,
                    };
                    break;
                case signupFieldType.IDENTIFICATION_TYPE:
                    validators.form.idType = { required };
                    break;
                case signupFieldType.DATE_OF_BIRTH:
                    validators.form.dob = { required };
                    break;
                case signupFieldType.REGION_ID:
                    validators.form.location = { required };
                    break;
            }
        }
        return validators;
    },
    computed: {
        ...mapGetters({
            isLoading: coreGetter.IS_LOADING,
            token: authGetter.SECURED_TOKEN,
            jurisdictionCountry: platformGetter.GET_COUNTRY,
            captchaError: authGetter.GET_CAPTCHA_ERROR,
            countryCodeIs: platformGetter.COUNTRY_CODE_IS,
            brandPreference: platformGetter.GET_BRAND_PREFERENCE,
            phoneNumberValidation: platformGetter.GET_PHONE_NUMBER_VALIDATION,
        }),
        ...mapState({
            authError: (state) => state.platform.auth.error,
            user: (state) => state.platform.settings.user,
            phonePrefix: (state) => getObjectField(state, 'platform.settings.brandIdentity.phoneCountryCode'),
            storedPhoneNumber: (state) => state.platform.auth.phoneNumber,
            regions: (state) => state.platform.regions,
            validationScenariosFields: (state) =>
                getObjectField(state, 'platform.settings.brandIdentity.validationScenarios.signUp.fields', []),
            termsAndConditions: (state) => state.platform.config.termsAndConditions,
        }),
        termsAndConditionsContent() {
            return { content: { content: this.termsAndConditions } };
        },
        error() {
            return this.captchaError || this.authError;
        },
        agreementText() {
            const { age } = this.brandPreference;
            return age ? this.$t('ui.registration.agreementWithAge', { indefinite: true, age }) : this.$t('ui.registration.agreement');
        },
        errorMessages() {
            const idNumber = this.$t(`ui.countries.${this.jurisdictionCountry}.form.error.${this.form.idType}`, { indefinite: true });
            const name = this.$t('ui.common.form.error.name');
            const acceptTerms = this.$t('ui.common.form.error.acceptTerms');

            return {
                firstName: { name },
                lastName: { name },
                ...(idNumber && { idNumber: { idNumber } }),
                isAgreementAccepted: { isAccepted: acceptTerms },
            };
        },
        inProgress() {
            return this.isLoading(authAction.REGISTER);
        },
        fetchingRegions() {
            return this.isLoading(generalAction.FETCH_REGIONS);
        },
        nationality() {
            return this.brandPreference.nationality || '';
        },
        nationalityByJur() {
            return this.form.nationality === this.nationality;
        },
        nationalities() {
            const nationality = this.nationality;
            return NATIONALITIES.sort((x, y) => (x === nationality ? -1 : y === nationality ? 1 : 0));
        },
        idTypeOptions() {
            const { countryCode } = this.brandPreference;
            return (
                (idTypeOptionsByJurisdiction[countryCode] || []).filter(({ nonNational }) => !nonNational === this.nationalityByJur) || {}
            );
        },
        idNumberField() {
            const option = (this.form.idType && this.idTypeOptions.find(({ key }) => key === this.form.idType)) || {
                key: 'NON_NATIONAL_LABEL',
            };
            return {
                ...option,
                key: option.suffix ? option.key + option.suffix : option.key,
            };
        },
        showCaptcha() {
            const { pathsWithCaptcha } = this.brandPreference;
            if (!pathsWithCaptcha) return false;
            return pathsWithCaptcha.includes(userEndpoints.register) || pathsWithCaptcha.includes('/' + userEndpoints.register);
        },
        isSubmitDisabled() {
            const captcha = this.showCaptcha && !this.token;
            return captcha || this.inProgress || this.$v.form.$invalid;
        },
    },
    watch: {
        idTypeOptions(options) {
            if (options.length === 1) {
                this.form.idType = options[0].key;
                this.$v.form.idType.$touch();
            }
        },
    },
    created() {
        if (this.storedPhoneNumber) {
            this.form.phoneNumber = helper.removeCountryCode(this.storedPhoneNumber, this.phonePrefix);
        }
        this.form.nationality = this.nationality;
        if (this.isFieldExist(signupFieldType.REGION_ID)) {
            this.$store.dispatch(generalAction.FETCH_REGIONS);
        }
    },
    mounted() {
        if (this.phoneNumberValidation && this.form.phoneNumber) this.$v.form.phoneNumber.$touch();
    },
    beforeDestroy() {
        this.$store.dispatch(authAction.RESET_ERROR);
        this.$store.commit(authMutation.SET_PHONE_NUMBER, {
            phoneNumber: this.form.phoneNumber,
            phonePrefix: this.phonePrefix,
        });
    },
    methods: {
        trackLinkClick() {
            this.$emit('handleClick', { key: 'login' });
            this.$gtm.query({
                event: 'login_click',
                click_element: 'link',
            });
        },
        register() {
            if (this.brandPreference.isNeedToAcceptTerms) {
                this.$v.isAgreementAccepted.$touch();
                if (this.$v.isAgreementAccepted.$invalid) {
                    return;
                }
            }
            const data = {
                phoneNumber: this.form.phoneNumber,
                password: this.form.password,
                country: this.jurisdictionCountry,
                ...(this.isFieldExist(signupFieldType.FIRST_NAME) && {
                    firstName: helper.normalizeName(this.form.firstName),
                }),
                ...(this.isFieldExist(signupFieldType.LAST_NAME) && {
                    lastName: helper.normalizeName(this.form.lastName),
                }),
                ...(this.isFieldExist(signupFieldType.REGION_ID) && {
                    regionId: this.form.location,
                }),
                ...(this.isFieldExist(signupFieldType.NATIONALITY) && {
                    nationality: this.form.nationality.toUpperCase(),
                }),
                ...(this.isFieldExist(signupFieldType.IDENTIFICATION_NUMBER) && {
                    identificationNumber: this.countryCodeIs.GH ? this.setGhanaIDNumber() : this.form.idNumber,
                }),
                ...(this.isFieldExist(signupFieldType.IDENTIFICATION_TYPE) && {
                    identificationType: this.form.idType,
                }),
                ...(this.isFieldExist(signupFieldType.DATE_OF_BIRTH) && {
                    dateOfBirth: this.form.dob,
                }),
            };
            this.$store
                .dispatch(authAction.REGISTER, data)
                .then(() => {
                    this.$emit('success');
                })
                .catch(({ errorCode, message } = {}) => {
                    this.$store.dispatch(authAction.NEED_UPDATE_SECURED_TOKEN, { needUpdate: true });

                    errorCode && this.$scroll.scrollTo(0);
                    if (VERIFICATION_ERRORS.includes(errorCode)) {
                        this.$router.push({
                            name: routeName.ACCOUNT_VERIFICATION,
                            params: {
                                phoneNumber: this.form.phoneNumber,
                                verificationCodeSent: true,
                                isPasswordRequired: false,
                            },
                        });
                        this.$store.commit(authMutation.SET_OTP_TIMER, new Date().getTime());
                    }
                });
        },
        onNationalityChange() {
            this.form.idType = null;
            this.$v.form.nationality.$touch();
        },
        onIdNumberChange(value) {
            if (!this.form.idType) {
                this.$v.form.idType.$touch();
            }
            this.form.idNumber = value;
        },
        setGhanaIDNumber() {
            if (this.form.idType === 'GHANA_CARD') {
                const ghanaId = this.form.idNumber.replace(/\D/g, '');
                return `GHA-${ghanaId.slice(0, 9)}-${ghanaId.slice(9)}`;
            } else {
                return this.form.idNumber;
            }
        },
        isFieldExist(fieldName) {
            return this.validationScenariosFields.some((field) => field.fieldName === fieldName);
        },
        onTermsCheckChange(checked) {
            this.isAgreementAccepted = checked;
            this.$v.isAgreementAccepted.$reset();
        },
    },
};
</script>

<style lang="scss" scoped>
.join-now {
    display: flex;
    flex-flow: column nowrap;
    .login-text {
        @extend %small-details-font-400;
        text-align: center;
        margin-top: 16px;
    }
}

.terms {
    @extend %small-details-font-400;
    margin: 0 0 16px;
    display: flex;
    align-items: flex-start;
    color: $grey-text;
    order: 0;
    &-horizontal-center {
        margin: 16px auto 0;
        order: 2;
    }
    &-checkbox {
        margin: 0;
    }
    &-text {
        @extend %small-details-text;

        ::v-deep {
            .rich-text-components * {
                @extend %small-details-text;
            }
        }
    }
}

input {
    width: 100%;
}

.notify.error {
    margin: 0 0 14px;
}
// TODO: create SelectOptions component
select {
    &.error {
        border-color: $error-red;
        border-width: $input-focus-error-border-width;

        &.warn {
            background: $message-warning;
        }
    }

    &.valid {
        border-color: $greenish;
        border-width: $input-border-width;
    }

    &[disabled] {
        background: $light-grey;
        color: $grey-text;
    }

    option {
        background: $white-bg;
    }
}

.ghana-id {
    @extend %body-normal-font-400;
    color: $disabled-text;
    background-color: $disabled-bg;
    padding: 7px 8px;
    width: 44px;
    border: 1px solid $disabled-bg;
}

.column-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.button-submit {
    order: 1;
}
.captcha-widget {
    order: 1;
}
</style>
