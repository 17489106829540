<template>
    <transition
        name="modal"
        @before-enter="handleTransitionSteps('before-enter')"
        @after-enter="handleTransitionSteps('after-enter')"
        @before-leave="handleTransitionSteps('before-leave')"
    >
        <div
            v-if="isVisible"
            ref="modal"
            class="modal-mask"
            :style="[position, { alignItems: align }, { marginTop: positionDelta.top }]"
            :class="{
                overlay: defaults.overlay,
                'back-drop': defaults.backDrop,
                fixed: fixed,
                'modal-mask-highest': isHighest,
            }"
        >
            <div class="modal-wrapper" :class="{ 'outside-spacing': defaults.outsideSpacing }" :style="{ width: width }">
                <div
                    v-click-outside:[clickOutside]="handleClickOutside"
                    class="modal-container"
                    :class="{ 'modal-container-scrollable': isScrollable }"
                >
                    <div
                        v-if="defaults.header || $slots.header || $slots['header-text']"
                        class="modal-header"
                        :class="{ 'modal-header-empty': isEmptyHeader }"
                    >
                        <template v-if="!isEmptyHeader">
                            <div v-if="defaults.header.text || $slots['header-text']" class="modal-header-text">
                                <slot name="header-text">
                                    <template v-if="defaults.header">
                                        <h4>{{ defaults.header.text }}</h4>
                                    </template>
                                </slot>
                            </div>
                            <slot name="header">
                                <template v-if="defaults.header">
                                    <a
                                        v-if="defaults.header.button"
                                        class="modal-header-button"
                                        :class="{ 'no-title': !(defaults.header.text || $slots['header-text']) }"
                                        @click.stop.prevent="$emit(defaults.header.button.event, defaults.header.button.args)"
                                    >
                                        <SvgIcon vertical-align="top" icon-id="icon-close" />
                                    </a>
                                </template>
                            </slot>
                        </template>
                    </div>
                    <Spinner :listen="spinnerTriggers" class="inset" />
                    <div class="modal-body">
                        <slot />
                    </div>
                    <div v-if="defaults.footer || $slots.footer" class="modal-footer" :class="{ shadow: isScrollable }">
                        <slot name="footer">
                            <template v-if="defaults.footer">
                                <p class="modal-footer-text">{{ defaults.footer.text }}</p>
                                <div style="text-align: center">
                                    <a
                                        v-for="(button, index) in defaults.footer.buttons"
                                        :key="index"
                                        class="button-primary button"
                                        @click="$emit(button.event, button.args)"
                                    >
                                        {{ button.text }}
                                    </a>
                                </div>
                            </template>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { helper } from '@/modules/core';
import { Spinner } from '@/modules/core/components';

import Modal from './index';

export default {
    name: 'Modal',
    components: { Spinner },
    props: {
        name: {
            required: true,
            type: String,
        },
        options: {
            type: Object,
            default: () => {
                return {};
            },
            validator(value) {
                const keys = Object.keys(value).filter((key) => {
                    return ['footer', 'overlay', 'backDrop', 'header', 'position', 'outsideSpacing'].indexOf(key) === -1;
                });
                return !keys.length;
            },
        },
        width: {
            type: String,
            default: '600px',
            validator(value) {
                const number = parseInt(value);
                return ['px', '%', 'vw'].indexOf(value.replace(number, '')) !== -1;
            },
        },
        align: {
            type: String,
            default: '',
        },
        fixed: {
            type: Boolean,
            default: false,
        },
        clickOutside: {
            type: Boolean,
            default: true,
        },
        outsideSpacing: {
            type: Boolean,
            default: true,
        },
        isHighest: {
            type: Boolean,
            default: false,
        },
        isScrollable: {
            type: Boolean,
            default: false,
        },
        isEmptyHeader: {
            type: Boolean,
            default: false,
        },
        avoidCollisionWithFooter: {
            type: Boolean,
            default: false,
        },
        spinnerTriggers: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    data() {
        return {
            isShown: false,
            isVisible: false,
            isTransitionEnter: false,
            slot: {},
            defaults: {
                header: { button: { class: 'button-close', event: 'close', args: '' } },
                position: { left: null, top: null, bottom: null, right: null },
                outsideSpacing: true,
            },
        };
    },
    computed: {
        position() {
            if (this.isVisible) {
                return this.defaults.position;
            }
            return { ...this.defaults.position, left: null, top: null };
        },
        positionDelta() {
            const position = {};
            if (this.avoidCollisionWithFooter) {
                const footer = document.querySelector('.main-footer');
                if (this.isTransitionEnter && footer && this.$refs.modal) {
                    const footerRect = footer.getBoundingClientRect();
                    const modalRect = this.$refs.modal.getBoundingClientRect();

                    if (modalRect.top + modalRect.height > footerRect.top) {
                        position.top = footerRect.top - modalRect.top - modalRect.height + 'px';
                    }
                }
            }
            return position;
        },
    },
    mounted() {
        this.defaults = this.mergeOptions(this.defaults, this.options);
    },
    beforeMount() {
        Modal.event.$on('toggle', this.handleToggleEvent);
    },
    beforeDestroy() {
        const modals = this.$modal.active;
        modals.indexOf(this.name) >= 0 && modals.splice(modals.indexOf(this.name), 1);
        Modal.event.$off('toggle', this.handleToggleEvent);
    },
    methods: {
        mergeOptions: (target, source) => helper.deepMerge(target, source),
        toggle(nextState, params) {
            if (nextState === this.isVisible) {
                return;
            }
            if (params) {
                this.defaults = this.mergeOptions(this.defaults, params);
            }
            this.isVisible = !this.isVisible;
        },
        handleToggleEvent(name, state, params) {
            if (this.name === name) {
                const nextState = typeof state === 'undefined' ? !this.isVisible : state;
                const modals = this.$modal.active;
                const modalParams = this.$modal.params;
                const isActiveModal = modals.includes(name);
                if (!isActiveModal && nextState) {
                    modals.push(name);
                    if (params) modalParams[name] = params;
                } else if (isActiveModal && !nextState) {
                    modals.splice(modals.indexOf(name), 1);
                    delete modalParams[name];
                } else {
                    return;
                }
                this.toggle(nextState, params);
                this.$nextTick(() => {
                    this.callAfterEvent(nextState);
                });
            }
        },
        callAfterEvent(state) {
            const eventName = state ? 'opened' : 'closed';
            this.$emit(eventName);
        },
        handleClickOutside() {
            if (this.isVisible) {
                this.$emit(this.defaults.header.button.event, this.defaults.header.button.args);
            }
        },
        handleTransitionSteps(step) {
            switch (step) {
                case 'before-enter':
                    this.isTransitionEnter = true;
                    break;
                case 'after-enter':
                    this.handleModalState();
                    break;
                case 'before-leave':
                    this.isTransitionEnter = false;
                    this.handleModalState();
                    break;
                default:
                    break;
            }
        },
        handleModalState() {
            this.isShown = !this.isShown;
        },
    },
};
</script>

<style scoped lang="scss">
.modal {
    position: relative;

    &-container {
        color: $betpawa-black;

        &-scrollable {
            max-height: 90vh;
            overflow: hidden;
        }
    }

    &-header {
        &-empty {
            padding-top: 20px;

            .small & {
                padding-top: 0;
            }

            @include maxbasic {
                padding-top: 0;
            }
        }

        &-text {
            padding: 20px 10px 15px 30px;

            .small & {
                padding: 10px 5px 15px 15px;
            }

            @include maxbasic {
                padding: 10px 5px 15px 15px;
            }
        }

        &-button {
            display: inline-block;
            width: 12px;
            height: 12px;
            margin: 20px 30px 0 0;

            .small & {
                margin: 10px 15px 0 0;
            }

            @include maxbasic {
                margin: 10px 15px 0 0;
            }

            svg {
                height: 12px;
                width: 12px;
                vertical-align: top;
            }

            &.no-title {
                margin: 10px 10px 0 0;
            }
        }
    }

    &-footer {
        padding: 0 20px;
        margin-top: 15px;

        &.shadow {
            box-shadow: 0px -6px 14px -10px grey;
            padding-top: 15px;
            margin-top: 0;
        }
    }

    &-header,
    &-footer {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;

        .button {
            margin-left: 10px;
            align-self: flex-end;
        }

        &-text {
            flex: 1;
            margin: 0;
        }
    }

    &-body,
    &-footer {
        .small & {
            padding: 0 15px;
        }

        @include maxbasic {
            padding: 0 15px;
        }
    }

    &-body {
        padding: 12px 16px 0;
        overflow: auto;
    }

    &-mask {
        width: 100%;
        position: absolute;
        left: 0;
        z-index: 998;
        display: flex;
        justify-content: center;
        transition: opacity 0.3s ease;
        font-size: 0.9rem;

        &-highest {
            z-index: 1002;
        }

        &.fixed {
            align-items: center;
            position: fixed;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            width: auto;
        }

        &.overlay {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding: 0;
            background-color: rgba(0, 0, 0, 0.5);
        }

        &.back-drop {
            &:before {
                content: '';
                position: fixed;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                bottom: 0;
                padding: 0;
                background-color: rgba(0, 0, 0, 0.5);
            }
        }
    }

    &-wrapper {
        width: 100%;
        position: relative;
        display: flex;
        margin: $modal-wrapper-margin;
        border: $modal-wrapper-border;

        &.outside-spacing {
            max-width: 90vw;
            max-height: 90vh;
        }

        .overlay & {
            align-items: start;
        }
    }

    &-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        padding-bottom: 23px;
        background-color: $white-bg;
        border-radius: $border-radius;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        transition: all 0.3s ease;

        .small & {
            padding-bottom: 10px;
        }

        @include maxbasic {
            padding-bottom: 10px;
        }
    }

    &-enter {
        opacity: 0;
    }

    &-leave-to {
        opacity: 0;
    }

    &-enter &-container,
    &-leave-active &-container {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
}
</style>
