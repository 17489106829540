import { render, staticRenderFns } from "./JackpotBet.vue?vue&type=template&id=4fb4cd67&scoped=true"
import script from "./JackpotBet.vue?vue&type=script&lang=js"
export * from "./JackpotBet.vue?vue&type=script&lang=js"
import style0 from "./JackpotBet.vue?vue&type=style&index=0&id=4fb4cd67&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fb4cd67",
  null
  
)

export default component.exports