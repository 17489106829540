<template>
    <div>
        <div
            v-for="bet in bets"
            :key="bet.eventId"
            class="betslip-bet"
            :class="{ 'not-available': isOfflinePrice(bet.price) || bet.rejectPrice || !bet.price }"
        >
            <Modal :name="getTwoUpModalId(bet)" width="100%" :options="{ position: { top: 0 } }" @close="$modal.hide(getTwoUpModalId(bet))">
                <renderer :input="$t('ui.iconTooltips.twoUp')" />
            </Modal>
            <div class="remove-bet" data-test-id="removeBet" @click="$emit('remove', bet)">
                <SvgIcon
                    class="icon-remove"
                    :class="{
                        offline: isOfflinePrice(bet.price),
                        'without-hover': $mq.isSmall,
                    }"
                    icon-id="icon-cross"
                />
            </div>
            <div class="betslip-game">
                <div class="game-info">
                    <span class="bet-match">
                        <EventNameWithLink
                            :title="bet.name"
                            :event-id="bet.eventId"
                            :is-link="
                                $isEventLive({
                                    startDate: bet.rawDate,
                                    isVirtual: isVirtual(bet),
                                })
                            "
                        />
                    </span>
                    <Badge
                        v-if="bet.market.twoUp"
                        class="pointer"
                        type="best-odds"
                        icon-name="icon-two-up"
                        @click.native="$modal.show(getTwoUpModalId(bet))"
                    />
                </div>
                <div class="bet-chosen">
                    <span class="bet-pick" :class="{ 'with-not-available': bet.previousPrice }">
                        <SvgIcon
                            v-if="bet.isLive"
                            icon-id="icon-live"
                            class="icon-status"
                            :class="{ 'not-available': isOfflinePrice(bet.price) }"
                        />
                        {{ generateSelectionName(bet) }}
                    </span>
                    <div class="bet-price">
                        <template v-if="offlinePrices.includes(bet.price.Id) || !bet.price.PriceRaw">
                            <span class="previous-price">
                                {{ $t('ui.betslip.notAvailable') }}
                            </span>
                        </template>
                        <template v-else-if="!bet.rejectPrice">
                            <span v-if="bet.previousPrice && bet.previousPrice.price" class="previous-price">
                                {{ $t('ui.betslip.previousPrice', { price: bet.previousPrice.price }) }}
                            </span>
                            {{ isOfflinePrice(bet.price) ? $t('ui.betslip.notAvailable') : bet.price.Price }}
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { helper } from '@/modules/core';
import { betslipType } from '@/modules/sport';
import { isEventLive } from '@/modules/sport/utils/event/is-event-live';
import { EventNameWithLink } from '@/modules/sport/components';
import Badge from '@/components/Badge.vue';

export default {
    name: 'BetList',
    components: { Badge, EventNameWithLink },
    props: {
        bets: {
            require: true,
            type: Array,
        },
        offlinePrices: Array,
    },
    methods: {
        $interpolate: helper.interpolate,
        $isEventLive: isEventLive,
        generateSelectionName(data) {
            const { market: { name, displayName, twoUp } = {}, price: { Hcp: hcp, Name: priceName } = {}, name: eventName } = data || {};

            return helper.formulateMarketTitle({
                eventName,
                marketName: name,
                displayName,
                priceName,
                hcp,
                additionalText: twoUp ? this.$t('ui.event.market.twoUp') : '',
            });
        },
        isOfflinePrice(price) {
            return this.offlinePrices.includes(price.Id) || !price.PriceRaw;
        },
        isVirtual(bet) {
            return bet.type === betslipType.VIRTUAL;
        },
        getTwoUpModalId({ eventId }) {
            return `betlist-two-up-${eventId}`;
        },
    },
};
</script>

<style scoped lang="scss">
.betslip-bet {
    position: relative;
    display: flex;
    border-top: 1px solid $betslip-border-color;
    align-items: stretch;
    background: $betslip-betlist-bet-background;

    &.not-available {
        border-right: 4px solid $dark-red;
        background: $betslip-betlist-bet-background-error;
    }
}
.betslip-game {
    flex-grow: 1;
    padding: 8px;
    background: $betslip-game-background;
}

.bet-match {
    color: $grey-text;
}

.remove-bet {
    flex-basis: 8%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @include oldschool {
        padding-right: 5px;
    }

    .icon-remove {
        max-width: 12px;
        max-height: 12px;
        transform: translateY(-6%);
        vertical-align: middle;
        fill: $betslip-betlist-bet-icon;
    }

    &:hover .icon-remove:not(.without-hover),
    .icon-remove.offline {
        fill: $error-red;
    }
}

.game-info {
    @extend %small-details-font-400;
    display: flex;
    justify-content: space-between;
}

.bet-chosen {
    @extend %small-details-font-700;
    display: flex;

    .previous-price {
        text-align: right;
        color: $light-red;
    }
}

.bet-price {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-left: auto;
    padding-left: 8px;
}

.betslip-game {
    flex-basis: 90%;
}

.bet-pick {
    width: 100%;

    &.with-not-available {
        width: auto;
    }
}
</style>
