import Vue from 'vue';
import { actionLoader, android, deviceType, helper } from '@/modules/core';
import { mutation as sportMutation } from '@/modules/sport/store/const';
import { action as betslipAction } from '@/modules/sport/store/modules/betslip/const';
import { authType, VERIFICATION_ERRORS } from '@/modules/platform';
import { action as platformAction, getter as platformGetter, mutation as platformMutation } from '@/modules/platform/store/const';
import { action, mutation, getter } from '@/modules/platform/store/modules/auth/const';
import { mutation as messagingMutation } from '@/modules/platform/store/modules/messaging/const';
import { mutation as paymentsMutation } from '@/modules/payment/store/const';
import { getter as translationsGetter, action as translationsAction } from '@/store/modules/translations/const';

import { getLocalTypes } from '@/store/utils'; // move to core BP-16141
import { auth, ledger, user } from '@/modules/platform/endpoints';
import { getObjectField } from '@/modules/core/utils/helper';

const DEFAULT_OTP_COUNTDOWN_SECONDS = 60;
const DEFAULT_OTP_COOLDOWN_MINUTES = 60;
const DEFAULT_OTP_ATTEMPTS_MAX_COUNT = 2;
const CAPTCHA_TOKEN_FIELD = 'cf-turnstile-response';
const getCaptchaHeader = (state) => {
    return { [CAPTCHA_TOKEN_FIELD]: state.captchaToken || CAPTCHA_TOKEN_FIELD };
};

const localAction = getLocalTypes(action);
const localMutation = getLocalTypes(mutation);
const _getter = getLocalTypes(getter);

const AUTH_STATE = {
    accountBalance: null,
    chipsBalance: null,
    isBalanceReady: false,
    isAuthenticated: false,
    firstLogIn: false,
    forcePasswordUpdate: false,
    errorCode: null,
    error: null,
    isUserVerified: false,
    nextCheckBalanceTimestamp: 0,
    otpAttempts: 0,
    nextOtpAttemptTimestamp: null,
    otpLimits: null,
    resetPasswordTrackingId: null,
};

const state = {
    ...AUTH_STATE,
    phoneNumber: null,
    otpTimer: null,
    captchaToken: null,
    captchaError: '',
    needUpdateSecuredToken: false,
    isSessionExpired: false,
};

const getters = {
    [_getter.SECURED_TOKEN]: (state) => state.captchaToken,
    [_getter.IS_AUTHENTICATED]: (state) => state.isAuthenticated,
    [_getter.IS_USER_VERIFIED]: (state) => state.isUserVerified,
    [_getter.GET_OTP_TIMER]: (state) => state.otpTimer,
    [_getter.GET_BALANCE]: (state) => state.accountBalance,
    [_getter.GET_CHIPS_BALANCE]: (state) => state.chipsBalance,
    [_getter.GET_CAPTCHA_ERROR]: (state) => state.captchaError,
    [_getter.GET_AUTH_ERROR]: (state) => state.error,
    [_getter.GET_OTP_LIMITS]: (state) => state.otpLimits,
    [_getter.GET_RESET_PASSWORD_TRACKING_ID]: (state) => state.resetPasswordTrackingId,
    [_getter.GET_OTP_COUNTDOWN_SECONDS]: (state, getters, rootState, rootGetters) => {
        const { otpCountdownSeconds } = rootGetters[platformGetter.GET_BRAND_PREFERENCE] || {};
        return otpCountdownSeconds || DEFAULT_OTP_COUNTDOWN_SECONDS;
    },
    [_getter.GET_OTP_ATTEMPTS_MAX_COUNT]: (state, getters, rootState, rootGetters) => {
        const { otpAttemptsMaxCount } = rootGetters[platformGetter.GET_BRAND_PREFERENCE] || {};
        return otpAttemptsMaxCount || DEFAULT_OTP_ATTEMPTS_MAX_COUNT;
    },
    [_getter.IS_OTP_ATTEMPTS_LIMITED]: (state, getters) => {
        if (!state.nextOtpAttemptTimestamp || state.otpAttempts < getters[_getter.GET_OTP_ATTEMPTS_MAX_COUNT]) return false;
        return state.nextOtpAttemptTimestamp > new Date().getTime();
    },
    [_getter.GET_STORED_PHONE_NUMBER]: (state, getters, rootState) => {
        const { phoneNumber } = rootState.platform.settings.user;
        return phoneNumber || state.phoneNumber;
    },
    [_getter.NEED_UPDATE_SECURED_TOKEN]: (state) => state.needUpdateSecuredToken,
    [_getter.IS_SESSION_EXPIRED]: (state) => state.isSessionExpired,
};

const mutations = {
    [localMutation.SET_AUTH](state, payload) {
        Object.assign(state, payload);
    },
    [localMutation.RESET_AUTH](state) {
        Object.assign(state, AUTH_STATE);
    },
    [localMutation.SET_ERROR](state, error) {
        if (typeof error === 'string') {
            state.error = error;
        } else {
            const { errorMessage, errorCode } = error;
            state.error = errorMessage;
            state.errorCode = errorCode;
        }
    },
    [localMutation.SET_OTP_TIMER](state, timer = null) {
        state.otpTimer = timer;
    },
    [localMutation.RESET_ERROR](state) {
        state.error = null;
    },
    [localMutation.SET_PHONE_NUMBER](state, { phoneNumber, phonePrefix }) {
        state.phoneNumber = helper.removeCountryCode(phoneNumber || null, phonePrefix);
    },
    [localMutation.SET_SECURITY_TOKEN](state, token = null) {
        state.captchaToken = token;
    },
    [localMutation.SET_CAPTCHA_ERROR](state, error = '') {
        state.captchaError = error;
    },
    [localMutation.RESET_CAPTCHA_ERROR](state) {
        state.captchaError = '';
    },
    [localMutation.SET_OTP_ATTEMPTS](state, nextOtpAttemptTimestamp) {
        state.otpAttempts++;
        state.nextOtpAttemptTimestamp = nextOtpAttemptTimestamp;
    },
    [localMutation.RESET_OTP_ATTEMPTS](state) {
        state.otpAttempts = 0;
    },
    [localMutation.SET_OTP_LIMITS](state, limits) {
        state.otpLimits = limits;
    },
    [localMutation.SET_RESET_PASSWORD_TRACKING_ID](state, trackingId) {
        state.resetPasswordTrackingId = trackingId;
    },
    [localMutation.NEED_UPDATE_SECURED_TOKEN](state, needUpdate) {
        state.needUpdateSecuredToken = needUpdate;
    },
    [localMutation.SET_SESSION_EXPIRED](state, value) {
        state.isSessionExpired = value;
    },
};

const actions = {
    [localAction.RESET_USER]({ commit, dispatch }) {
        commit(localMutation.RESET_AUTH);
        commit(messagingMutation.RESET_MESSAGING, null, { root: true });
        commit(platformMutation.SET_CAMPAIGN_ELIGIBILITY, {}, { root: true });
        commit(platformMutation.SET_USING_CAMPAIGN_ELIGIBILITY, false, { root: true });
        commit(sportMutation.SET_SPORT_RADAR_JWT, '', { root: true });
        commit(sportMutation.SET_SAVED_EVENT_FILTERS, null, { root: true });
        commit(platformMutation.UPDATE_PREFERENCE, { first_bet: undefined }, { root: true });

        if (deviceType.isApp()) {
            android.setFlag();
        }
        // removing auth token for unauthorized user by setting cookie expiration date in the past
        const cookiesForDelete = ['x-pawa-token', 'Token', 'x-pawa-user-uuid', 'userId'];
        const expires = new Date(0);
        cookiesForDelete.forEach((item) => {
            document.cookie = `${item}=; expires=${expires}`;
        });
        return Promise.allSettled([
            dispatch(betslipAction.RESET_USER_BETS_DATA, null, { root: true }),
            dispatch(platformAction.LOAD_AGI_SETTINGS, null, { root: true }),
        ]);
    },
    [localAction.PRE_REGISTER]: actionLoader(action.PRE_REGISTER, (store, payload) => {
        return Vue.$http.put(auth.preregistration, payload);
    }),
    [localAction.LOGIN]: actionLoader(action.LOGIN, ({ commit, dispatch, state, rootGetters }, payload) => {
        commit(localMutation.RESET_CAPTCHA_ERROR);
        return Vue.$http
            .post(auth.login, {
                username: payload.username,
                password: payload.password,
                rememberMe: !!payload.rememberMe,
            })
            .then(({ data }) => {
                const { forcePasswordUpdate } = data;
                dispatch(
                    platformAction.LOAD_AGI_SETTINGS,
                    { authType: authType.LOGIN, keepLoggedIn: !!payload.rememberMe },
                    { root: true }
                ).then(() => {
                    commit(localMutation.SET_AUTH, { isAuthenticated: true });
                    /**
                     * When enabled, changes app language after
                     * login if saved/preferred language
                     * conflicts with currenlty selected
                     * {param} [useSavedLanguageOnLogin] - brand.preference property
                     */
                    const settings = rootGetters[platformGetter.GET_SETTINGS];
                    const { useSavedLanguageOnLogin } = getObjectField(settings, 'brand.preference', {});
                    if (useSavedLanguageOnLogin) {
                        const selectedLanguage = rootGetters[translationsGetter.GET_SELECTED_LANGUAGE];
                        const preferredLanguage = getObjectField(settings, 'preference.language', selectedLanguage);
                        if (preferredLanguage !== selectedLanguage) {
                            dispatch(translationsAction.SWITCH_LANGUAGE, preferredLanguage, { root: true });
                        }
                    }
                });
                commit(localMutation.SET_AUTH, { forcePasswordUpdate });
                commit(localMutation.RESET_ERROR);
                commit(platformMutation.SET_FORCE_GET_CONTENT, true, { root: true });
                commit(paymentsMutation.SET_DEPOSIT_COMPONENT_IS_VISIBLE, true, { root: true });

                // TODO: remove country when android moves to the new api
                android.trackState(payload.username, payload.password, payload.country);
            })
            .catch((error) => {
                const { errorCode } = error;
                const errorMessage = helper.processErrorResponse(error);
                const { isVerification } = payload || {};
                const hasNoErrorReason = !isVerification && !VERIFICATION_ERRORS.includes(errorCode);
                commit(localMutation.SET_ERROR, { errorMessage, errorCode });
                Vue.$gtm.query({
                    event:
                        (isVerification && 'verification_new_pin_failed') ||
                        (VERIFICATION_ERRORS.includes(errorCode) && 'login_failed_account_not_verified') ||
                        'login_failed',
                    ...(hasNoErrorReason && { reason: errorMessage || 'unknown' }),
                });
                dispatch(localAction.NEED_UPDATE_SECURED_TOKEN, { needUpdate: true });
            })
            .finally(() => dispatch(betslipAction.RESET_BETSLIP_ERROR, null, { root: true }));
    }),
    [localAction.LOGOUT]({ dispatch }) {
        return Vue.$http
            .post(auth.logout)
            .then(() => dispatch(localAction.RESET_USER))
            .catch((error) => console.error(`${action.LOGOUT} Response Error`, [error]));
    },
    [localAction.GET_CHIPS_BALANCE]({ commit, rootGetters } = {}) {
        const { chips = {} } = rootGetters[platformGetter.GET_SETTINGS];
        const { currency } = chips || {};
        return Vue.$http
            .post(ledger.userBalance, { currency: currency.symbol })
            .then(({ data }) => {
                const { balance: chipsBalance } = data || {};
                commit(localMutation.SET_AUTH, {
                    chipsBalance,
                });
            })
            .catch((error) => console.error(`${localAction.GET_CHIPS_BALANCE} Response Error`, [error]));
    },
    [localAction.GET_BALANCE]({ commit, dispatch, rootGetters, state }, { trackingData, force } = {}) {
        if (state.nextCheckBalanceTimestamp > new Date().getTime() && !force) return;
        const { user } = rootGetters[platformGetter.GET_SETTINGS];
        return Vue.$http
            .get(`${ledger.userBalance}?uuid=${user.userUuid}`)
            .then((response) => {
                const { data, status, statusCode } = response || {};
                if (data?.balance !== 0 && !Number(data?.balance)) {
                    Vue.$sentry.withScope((scope) => {
                        scope.setExtras({ response, data });
                        scope.setTag('status', status || statusCode);
                        scope.setTag('scope', 'balance');
                        scope.setTag('balance', 'success');
                        scope.setLevel('fatal');
                        Vue.$sentry.captureMessage('FATAL_ERROR_BALANCE_RESPONSE');
                    });
                }

                const { balanceCooldownSeconds } = rootGetters[platformGetter.GET_BRAND_PREFERENCE];
                const cooldownTime = (balanceCooldownSeconds || 0) * 1000;
                commit(localMutation.SET_AUTH, {
                    accountBalance: data.balance,
                    isBalanceReady: true,
                    nextCheckBalanceTimestamp: new Date().getTime() + cooldownTime,
                });

                dispatch(platformAction.TRACK_USER_DATA, null, { root: true });
                if (trackingData && trackingData.event) {
                    Vue.$gtm.query({
                        ...trackingData,
                        ...{ user: { balance: data.AccountBalance } },
                    });
                }
            })
            .catch((error) => {
                Vue.$sentry.withScope((scope) => {
                    const { status, statusCode } = error || {};
                    scope.setExtras({ error });
                    scope.setTag('status', status || statusCode);
                    scope.setTag('scope', 'balance');
                    scope.setTag('balance', 'error');
                    scope.setLevel('fatal');
                    Vue.$sentry.captureMessage('FATAL_ERROR_BALANCE_ERROR');
                });
                console.error(`${action.GET_BALANCE} Response Error`, [error]);
                commit(localMutation.SET_AUTH, { isBalanceReady: true });
            });
    },
    [localAction.REGISTER]: actionLoader(action.REGISTER, ({ commit, dispatch, state, rootGetters }, payload) => {
        const pinOrPassword = rootGetters[translationsGetter.PIN_OR_PASSWORD];
        commit(localMutation.RESET_CAPTCHA_ERROR);
        return Vue.$http
            .post(
                user.register,
                {
                    ...payload,
                },
                {
                    headers: {
                        ...getCaptchaHeader(state),
                    },
                    translationParams: {
                        authMethod: Vue.$t(`ui.common.${pinOrPassword}.authMethod`),
                        passRequirement: Vue.$t(`ui.common.${pinOrPassword}.passRequirement`),
                        passLengthRequirement: Vue.$t(`ui.common.${pinOrPassword}.passLengthRequirement`),
                        passLengthAuthMethod: Vue.$t(`ui.common.${pinOrPassword}.passLengthAuthMethod`),
                    },
                }
            )
            .then(({ data }) => {
                const { forcePasswordUpdate } = data;
                dispatch(platformAction.LOAD_AGI_SETTINGS, { authType: authType.REGISTER }, { root: true }).then(() => {
                    commit(localMutation.SET_AUTH, { isAuthenticated: true });
                });
                commit(localMutation.SET_AUTH, { forcePasswordUpdate });
                // TODO remove country when android moves to the new api
                android.trackState(payload.phoneNumber, payload.password, payload.country);
            })
            .catch((error) => {
                const { errorCode, message } = error;
                const isVerificationError = VERIFICATION_ERRORS.includes(errorCode);
                commit(localMutation.SET_ERROR, helper.processErrorResponse(error));
                dispatch(localAction.NEED_UPDATE_SECURED_TOKEN, { needUpdate: true });

                Vue.$gtm.query({
                    event: isVerificationError ? 'account_not_verified_sign_up_error' : 'sign_up_failed',
                    ...(!isVerificationError && { reason: message || 'unknown' }),
                });
                throw error;
            })
            .finally(() => dispatch(betslipAction.RESET_BETSLIP_ERROR, null, { root: true }));
    }),
    [localAction.VERIFY_ACCOUNT_BY_HASH]: actionLoader(action.VERIFY_ACCOUNT_BY_HASH, ({ commit, dispatch, rootGetters }, payload) => {
        commit(localMutation.RESET_ERROR);
        return Vue.$http
            .post(user.verifyAccountBySMS, payload)
            .then(({ data }) => {
                const { forcePasswordUpdate, userUuid, firstLogIn } = data;
                dispatch(platformAction.LOAD_AGI_SETTINGS, null, { root: true }).then(() => {
                    commit(localMutation.SET_AUTH, { isAuthenticated: true });
                });
                commit(localMutation.SET_AUTH, { forcePasswordUpdate, firstLogIn });
                Vue.$gtm.query({
                    event: 'verification_link',
                    user: {
                        userID: userUuid,
                    },
                });
            })
            .catch((error) => {
                commit(localMutation.SET_ERROR, helper.processErrorResponse(error));
                Vue.$gtm.query({
                    event: 'verification_link_failed',
                    reason: error.message || 'unknown',
                });
            });
    }),
    [localAction.UPDATE_PASSWORD_BY_OTP]: actionLoader(action.UPDATE_PASSWORD_BY_OTP, ({ commit, dispatch, state }, payload) => {
        commit(localMutation.RESET_ERROR);
        return Vue.$http
            .post(user.updatePassword, payload, {
                headers: {
                    ...getCaptchaHeader(state),
                },
            })
            .then(({ data }) => {
                const { forcePasswordUpdate } = data;
                dispatch(platformAction.LOAD_AGI_SETTINGS, null, { root: true }).then(() => {
                    commit(localMutation.SET_AUTH, { isAuthenticated: true });
                });
                commit(localMutation.SET_AUTH, { forcePasswordUpdate });
                Vue.$gtm.query({
                    event: 'update_password_by_otp',
                    trackingId: state.resetPasswordTrackingId,
                });
            })
            .catch((error) => {
                commit(localMutation.SET_ERROR, helper.processErrorResponse(error));
                dispatch(localAction.NEED_UPDATE_SECURED_TOKEN, { needUpdate: true });
                Vue.$gtm.query({
                    event: 'update_password_by_otp_failed',
                    trackingId: state.resetPasswordTrackingId,
                    reason: error?.message || 'unknown',
                });
            });
    }),
    [localAction.RESET_PASSWORD]: actionLoader(
        action.RESET_PASSWORD,
        ({ commit, state, getters, dispatch, rootGetters }, { areaCode, phoneNumber }) => {
            commit(localMutation.RESET_CAPTCHA_ERROR);
            commit(localMutation.RESET_ERROR);
            if (!getters[_getter.IS_OTP_ATTEMPTS_LIMITED]) commit(localMutation.SET_OTP_TIMER, new Date().getTime());
            return new Promise((resolve, reject) => {
                if (getters[_getter.IS_OTP_ATTEMPTS_LIMITED]) return resolve();
                Vue.$http
                    .post(
                        user.resetPassword,
                        {
                            areaCode,
                            phoneNumber,
                        },
                        {
                            headers: {
                                ...getCaptchaHeader(state),
                            },
                        }
                    )
                    .then(({ data }) => {
                        const { attemptsLeft } = data?.limits || {};
                        commit(localMutation.SET_OTP_LIMITS, data?.limits);
                        dispatch(localAction.UPDATE_OTP_ATTEMPTS);
                        Vue.$gtm.query({
                            event: 'reset_password',
                            trackingId: state.resetPasswordTrackingId,
                            ...(attemptsLeft && { attemptsLeft }),
                        });
                        resolve();
                    })
                    .catch((error) => {
                        commit(localMutation.SET_ERROR, helper.processErrorResponse(error));
                        dispatch(localAction.NEED_UPDATE_SECURED_TOKEN, { needUpdate: true });
                        Vue.$gtm.query({
                            event: 'reset_password_failed',
                            trackingId: state.resetPasswordTrackingId,
                            reason: error.message || 'unknown',
                        });
                        reject(error); // handled in ResetPassword.vue and AccountVerification.vue
                    });
            });
        }
    ),
    [localAction.SET_PASSWORD]: actionLoader(action.SET_PASSWORD, ({ commit, rootGetters }, payload) => {
        return Vue.$http
            .put(user.changePassword, payload)
            .then(() => {
                const { preference, user } = rootGetters[platformGetter.GET_SETTINGS];
                android.trackState(user.phoneNumber, payload.password, preference.country);
                Vue.$gtm.query({ event: 'set_password' });
            })
            .catch((error) => {
                commit(localMutation.SET_ERROR, helper.processErrorResponse(error));
                Vue.$gtm.query({
                    event: 'set_password_failed',
                    reason: error.message || 'unknown',
                });
                throw error;
            });
    }),
    [localAction.CHANGE_PASSWORD]: actionLoader(action.CHANGE_PASSWORD, ({ commit, rootGetters }, payload) => {
        return Vue.$http
            .post(user.changePassword, payload)
            .then(() => {
                const { preference, user } = rootGetters[platformGetter.GET_SETTINGS];
                android.trackState(user.phoneNumber, payload.newPassword, preference.country);
                commit(localMutation.RESET_ERROR);
                Vue.$gtm.query({ event: 'change_password' });
            })
            .catch((error) => {
                commit(localMutation.SET_ERROR, helper.processErrorResponse(error));
                Vue.$gtm.query({
                    event: 'change_password_failed',
                    reason: error.message || 'unknown',
                });
                throw error;
            });
    }),
    [localAction.VERIFY_KYC]: actionLoader(action.VERIFY_KYC, ({ commit }, payload) => {
        return Vue.$http
            .post(user.kycVerification, payload)
            .then(({ data }) => {
                const { success, bypass } = data;
                commit(platformMutation.SET_KYC, { success, bypass }, { root: true });
                if (!success && !bypass) {
                    commit(localMutation.SET_ERROR, Vue.$t('ui.payment.payout.ghana.errorKYC'));
                }
            })
            .catch((error) => {
                // Set unknown kyc values to trigger watcher
                commit(platformMutation.SET_KYC, null, { root: true });
                if (error) {
                    commit(localMutation.SET_ERROR, helper.processErrorResponse(error));
                } else {
                    commit(localMutation.SET_ERROR, Vue.$t('ui.payment.payout.ghana.errorKYCFail'));
                }
            });
    }),
    [localAction.SET_ERROR]({ commit }, error) {
        commit(localMutation.SET_ERROR, error);
    },
    [localAction.RESET_ERROR]({ commit }) {
        commit(localMutation.RESET_ERROR);
    },
    [localAction.ENABLE_ACCESS]({ commit }, token) {
        commit(localMutation.SET_SECURITY_TOKEN, token);
    },
    [localAction.UPDATE_OTP_ATTEMPTS]({ state, commit, rootGetters }) {
        const isOtpAttemptExpired = new Date().getTime() > state.nextOtpAttemptTimestamp;
        isOtpAttemptExpired && commit(localMutation.RESET_OTP_ATTEMPTS);

        const { otpCooldownMinutes } = rootGetters[platformGetter.GET_BRAND_PREFERENCE] || {};
        const nextOtpAttemptTimestamp = new Date().getTime() + (otpCooldownMinutes || DEFAULT_OTP_COOLDOWN_MINUTES) * 60000;
        commit(localMutation.SET_OTP_ATTEMPTS, nextOtpAttemptTimestamp);
    },
    [localAction.SELF_EXCLUDE]: actionLoader(action.SELF_EXCLUDE, ({ commit, dispatch }, payload) => {
        commit(localMutation.RESET_ERROR);
        return Vue.$http
            .put(user.selfExclude, {
                ...payload,
            })
            .then(() => {
                dispatch(localAction.LOGOUT);
                return true;
            })
            .catch((error) => {
                const { errorCode } = error;
                const errorMessage = helper.processErrorResponse(error);
                commit(localMutation.SET_ERROR, { errorMessage, errorCode });
            });
    }),
    [localAction.GENERATE_RESET_PASSWORD_TRACKING_ID]({ commit }) {
        const trackingId = Date.now().toString() + (Math.random() + 1).toString(36).substring(2);

        commit(localMutation.SET_RESET_PASSWORD_TRACKING_ID, trackingId);
    },
    [localAction.NEED_UPDATE_SECURED_TOKEN]({ commit }, { needUpdate }) {
        commit(localMutation.NEED_UPDATE_SECURED_TOKEN, needUpdate);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
