<template>
    <svg v-if="icon || altIcon" class="svg-icon" :style="{ verticalAlign }">
        <title v-if="title">{{ title }}</title>
        <use v-bind="{ 'xmlns:xlink': 'http://www.w3.org/1999/xlink', 'xlink:href': (icon || altIcon).url }" />
    </svg>
    <img v-else-if="image || altImage" :src="image" class="image-icon" :style="{ verticalAlign }" />
</template>

<script>
export default {
    name: 'SvgIcon',
    props: {
        title: {
            type: String,
            default: '',
        },
        iconId: {
            type: String,
            required: true,
            validator(iconName) {
                if (!iconName) {
                    console.warn('icon can not be empty');
                    return false;
                }
                return true;
            },
        },
        iconFallback: {
            type: String,
            required: false,
        },
        altIconId: {
            type: String,
            required: false,
        },
        altIconFallback: {
            type: String,
            required: false,
        },
        fallbackExt: {
            type: String,
            default: 'png',
        },
        verticalAlign: {
            type: String,
            default: 'middle',
        },
    },
    data() {
        return {
            icon: null,
            image: null,
            altIcon: null,
            altImage: null,
        };
    },
    watch: {
        iconId: {
            immediate: true,
            handler(source) {
                source &&
                    this.getSVGorFallbackImage(source, this.iconFallback).then(({ svg, image }) => {
                        this.icon = svg;
                        this.image = image;
                    });
            },
        },
        altIconId: {
            immediate: true,
            handler(source) {
                source &&
                    this.getSVGorFallbackImage(source, this.altIconFallback).then(({ svg, image }) => {
                        this.altIcon = svg;
                        this.altImage = image;
                    });
            },
        },
    },
    methods: {
        getSVGorFallbackImage(id) {
            return import(`@/assets/icons/${id}.svg`)
                .then((svg) => {
                    if (Object.prototype.hasOwnProperty.call(svg, 'default')) {
                        return { svg: svg.default };
                    } else {
                        return { svg };
                    }
                })
                .catch(() => {
                    return {
                        image: this.iconFallback && require(`@/assets/images/${this.iconFallback}.${this.fallbackExt}`),
                    };
                });
        },
    },
};
</script>

<style scoped lang="scss">
svg,
img {
    width: 32px;
    height: 32px;

    &.icon-statistics {
        width: 16px;
        height: 16px;
        fill: #f4f5f0;
    }

    &.icon-status {
        width: 14px;
        height: 14px;
        min-width: 14px;
        transform: translateY(-6%);
        fill: $icon-primary;
    }

    &.icon-language-select {
        width: 16px;
        height: 16px;
        min-width: 16px;
        fill: $icon-primary;
    }

    &.icon-language-flag {
        width: 22px;
        height: 16px;
        min-width: 16px;
        fill: $icon-primary;
    }

    @extend %icon-sizes;

    &.icon-message {
        fill: $main-text;

        &.icon-disabled {
            fill: $disabled-text;
        }
    }
}
</style>
