<template>
    <transition name="slide">
        <div
            class="table popover"
            :class="{
                'popover-smallest': $mq.isXXSmall,
            }"
        >
            <div class="popover-triangle" />
            <div class="row">
                <div v-if="$slots.left" class="row-cell left">
                    <slot name="left" />
                </div>
                <div v-if="$slots.right" class="row-cell right">
                    <slot name="right" />
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'Popover',
};
</script>

<style scoped lang="scss">
.popover {
    background-color: $betbar-bonus-background;
    position: relative;
    color: $betbar-bonus-text-color;

    &-triangle {
        content: '';
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid $primary-color;
        position: absolute;
        bottom: -6px;
        left: 22px;
    }

    .row-cell {
        vertical-align: middle;
        padding: 4px 12px;
    }

    &-smallest {
        .row-cell {
            padding: 4px 8px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
            width: 100vw;
        }
    }
}
</style>
