<template>
    <div v-if="providers" class="deposit-list">
        <h1 v-if="title" class="title">{{ title }}</h1>
        <p v-if="text" class="foreword">{{ text }}</p>
        <div class="thumbnails-container">
            <router-link
                v-for="(type, index) of providers"
                :key="`${index}-deposit-method-${type.id}`"
                class="thumbnail"
                :to="{ path: type.pageUrl }"
            >
                <div class="image">
                    <img v-if="type.mediaUrl" :src="type.mediaUrl" />
                    <div v-else class="text">{{ type.text }}</div>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { action, getter } from '@/modules/payment/store/const';

export default {
    name: 'DepositList',
    props: ['title', 'text'],
    computed: {
        ...mapGetters({
            providers: getter.GET_PROVIDERS_LIST,
        }),
    },
    mounted() {
        this.$store.dispatch(action.FETCH_PROVIDERS_OPTIONS);
    },
};
</script>

<style scoped lang="scss">
.deposit-list {
    background-color: $light-grey-bg;
    padding: 15px 10%;
    text-align: center;

    @include oldschool {
        padding: 15px 5px;
    }

    .title {
        margin: 0;
    }

    .foreword {
        padding: 10px;
    }

    .thumbnails-container {
        overflow: hidden;
        position: relative;

        .thumbnail {
            display: inline-block;
            margin: 7px 10px;
            vertical-align: middle;

            .image {
                padding: 7px;
                border: 2px solid $light-grey;
                border-radius: $deposit-list-thumbnail-border-radius;
                background: $deposit-list-thumbnail-image-background;

                @include hoverable {
                    &:hover {
                        border-color: $primary-color;
                    }
                }

                > div.text {
                    word-break: break-word;
                    height: 100%;
                    overflow: hidden;
                }

                img,
                > div {
                    vertical-align: middle;
                    width: 70px;
                    height: 70px;
                }
            }

            .text {
                text-align: center;
                padding: 7px 0;
                font-size: 0.8rem;
            }
        }
    }
}
</style>
