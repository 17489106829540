<template>
    <div>
        <div :id="widgetId" ref="container" class="cloudflare-widget" />
        <Spinner v-if="!isWidgetInitialized" visible class="cloudflare-spinner" />
    </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import { getter as authGetter, action as authAction, mutation as authMutation } from '@/modules/platform/store/modules/auth/const';
import { getter as platformGetter } from '@/modules/platform/store/const';

import { widgetSize, cloudflareEvents } from '@/js/plugins/cloudflare/const';
import { errorCodes } from '@/js/captcha-const';

import { Spinner } from '@/modules/core/components';

export default {
    name: 'CaptchaWidget',
    components: { Spinner },
    props: {
        widgetId: {
            type: String,
            required: false,
            default: 'captcha-widget',
        },
    },
    data: () => ({
        isWidgetInitialized: false,
        widgetLoadedTimeout: null,
    }),
    computed: {
        ...mapGetters({
            needUpdate: authGetter.NEED_UPDATE_SECURED_TOKEN,
            token: authGetter.SECURED_TOKEN,
            brandPreference: platformGetter.GET_BRAND_PREFERENCE,
        }),
        accessDeniedError() {
            return this.$t('errors.ACCESS_DENIED');
        },
        widgetNotLoadedError() {
            return this.$t('errors.CAPTCHA_FAILED_TO_LOAD');
        },
        byPass() {
            const { protection } = this.$route.query;
            return protection;
        },
        hasToken() {
            return this.byPass || this.token;
        },
        isEnabled() {
            return !this.byPass;
        },
    },
    watch: {
        needUpdate(value) {
            if (value) {
                this.$store.dispatch(authAction.NEED_UPDATE_SECURED_TOKEN, { needUpdate: false });
                this.callCloudflare({ needUpdateSecuredToken: value });
            }
        },
    },
    mounted() {
        this.enableAccess(this.hasToken);
        this.callCloudflare({ needUpdateSecuredToken: this.needUpdate });
    },
    beforeDestroy() {
        this.enableAccess();
        this.resetCaptchaError();

        if (this.isEnabled) {
            this.$cloudflare.turnstile && this.$cloudflare.turnstile.remove();
            this.$cloudflare.removeEventListeners && this.$cloudflare.removeEventListeners();
        }
    },
    methods: {
        ...mapActions({
            enableAccess: authAction.ENABLE_ACCESS,
        }),
        ...mapMutations({
            setCaptchaError: authMutation.SET_CAPTCHA_ERROR,
            resetCaptchaError: authMutation.RESET_CAPTCHA_ERROR,
        }),
        callback(token) {
            this.resetCaptchaError();
            this.enableAccess(token).then(() => {
                this.$emit('token', token);
            });
        },
        expired(expired) {
            this.enableAccess().then(() => {
                this.resetCaptcha();
                this.handleError(this.accessDeniedError, { expired, errorCode: errorCodes.EXPIRED });
            });
        },
        error(error) {
            this.enableAccess().then(() => {
                this.resetCaptcha();
                this.handleError(this.accessDeniedError, { error, errorCode: errorCodes.UNKNOWN });
            });
        },
        timeout(timeout) {
            this.enableAccess().then(() => {
                this.resetCaptcha();
                this.handleError(this.accessDeniedError, { timeout, errorCode: errorCodes.TIMEOUT }, false);
            });
        },
        handleError(message, data, emitEvent = true) {
            if (emitEvent) {
                this.setCaptchaError(message || '');
                this.$emit('error', message || '');
            }

            this.$sentry.withScope((scope) => {
                scope.setExtras({ message, data });
                scope.setTag('scope', 'turnstile');
                scope.setTag('turnstile', 'error');
                scope.setLevel('fatal');
                this.$sentry.captureMessage(`FATAL_ERROR_${data.errorCode}`);
            });

            message && console.error(message, data);
        },
        callCloudflare({ needUpdateSecuredToken }) {
            if (!this.isEnabled) return;

            if (!this.isWidgetInitialized) {
                // Starts a timeout to invoke the widgetNotLoaded() method if the widget fails to load
                this.setWidgetLoadTimeout();
                this.setCaptchaLoadListener();
            }

            this.$cloudflare
                .bootstrap({
                    callback: this.callback,
                    expired: this.expired,
                    error: this.error,
                    timeout: this.timeout,
                    widgetOptions: {
                        size: this.$mq.xsUp ? widgetSize.NORMAL : widgetSize.COMPACT,
                    },
                    id: `#${this.widgetId}`,
                })
                .catch((error) => {
                    this.handleError(this.accessDeniedError, {
                        error,
                        reason: 'Challenge not loaded!',
                        errorCode: errorCodes.NOT_LOADED,
                        needUpdateSecuredToken,
                    });
                });
        },
        resetCaptcha() {
            this.$cloudflare.turnstile && this.$cloudflare.turnstile.reset();
        },
        setCaptchaLoadListener() {
            // This check is necessary to prevent adding the listener multiple times.
            if (!this.$cloudflare.handleMessageEvent) {
                this.$cloudflare.addEventListeners(this.handleCloudflareMessage);
            }
        },
        setWidgetLoadTimeout() {
            if (this.brandPreference.widgetLoadCheckDelay && !this.widgetLoadedTimeout) {
                this.widgetLoadedTimeout = setTimeout(() => this.widgetNotLoaded(), this.brandPreference.widgetLoadCheckDelay);
            }
        },
        widgetNotLoaded() {
            // We set a random token so that the user can proceed further
            this.enableAccess('_');

            this.handleError(this.widgetNotLoadedError, {
                error: {},
                reason: 'Captcha widget not loaded!',
                errorCode: errorCodes.NOT_LOADED,
                needUpdateSecuredToken: this.needUpdate,
            });
        },
        handleCloudflareMessage(event) {
            if (event === cloudflareEvents.INIT) {
                this.isWidgetInitialized = true;
                this.$cloudflare.removeEventListeners();

                if (this.widgetLoadedTimeout) {
                    clearTimeout(this.widgetLoadedTimeout);
                }
            }
        },
    },
};
</script>

<style scoped lang="scss">
.cloudflare-widget {
    &:has(div) {
        text-align: center;
        padding: 0 0 16px 0;
    }
}

.cloudflare-spinner {
    align-items: center;
    position: relative;
    background: transparent;
    height: 70.4px; // Matching the widget's height to avoid button movement after loading
    padding: 0 16px 16px;
}
</style>
